import { User } from "oidc-client-ts";

import { oidcConfig } from "../hooks/providers/AuthProvider";

export const getUserInfo = () => {
    const oidcStorage = sessionStorage.getItem(
        `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`
    );
    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
};

export const onSigninCallback = (user: User | void) => {
    window.history.replaceState({}, document.title, window.location.pathname);
    // @ts-ignore
    window.location = user.state || "/";
};
