import { Spinner, IconButton } from "@chakra-ui/react";
import { t } from "i18next";
import { useCallback } from "react";
import { FiPlus } from "react-icons/fi";
import { InvoiceQueryDto } from "../../../api/invoiceApi";

type CreateInvoiceButtonProps = {
    rowId: string;
    claimId: string;
    claimIdRef: React.MutableRefObject<string | undefined>;
    createInvoice: () => void;
    creatingInvoice: boolean;
    invoiceArgs: InvoiceQueryDto | undefined,
    isLoadingClaimsInfo: boolean;
    claimsInfoArgs: string | undefined;
};

const CreateInvoiceButton = ({
    rowId,
    claimId,
    claimIdRef,
    createInvoice,
    creatingInvoice,
    invoiceArgs,
    isLoadingClaimsInfo,
    claimsInfoArgs
}: CreateInvoiceButtonProps) => {
    const handleClicked = useCallback(
        (e: React.UIEvent) => {
            if (e.type === "keyup") {
                const keyEvent = e as React.KeyboardEvent;
                if (keyEvent.key === "Enter") {
                    e.stopPropagation();
                    claimIdRef.current = claimId;
                    createInvoice();
                }
            }
            if (e.type === "click") {
                e.stopPropagation();
                claimIdRef.current = claimId;
                createInvoice();
            }
        },
        [claimId, claimIdRef, createInvoice]
    );

    if (
        (creatingInvoice && invoiceArgs?.claimId === claimId)
            || (isLoadingClaimsInfo && claimsInfoArgs === claimId)
    ) {
        return <Spinner />;
    }

    return (
        <IconButton
            id={"createInvoiceBtn" + rowId}
            size="sm"
            aria-label={t("claimsTable.createInvoice")}
            as={FiPlus}
            variant="ghost"
            p="0.2rem"
            onClick={handleClicked}
            onKeyUp={handleClicked}
            tabIndex={0}
            bg="anchor.navy.50"
            color="anchor.navy.900"
            _hover={{
                bg: "anchor.navy.100",
                color: "anchor.navy.900"
            }}
        />
    );
};

export default CreateInvoiceButton;
