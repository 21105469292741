import { Badge, Tooltip } from "@chakra-ui/react";

import { BankAccountDto, CompanyDto, AttachmentDto, InvoiceDetailsDto, InvoiceStatus, InvoiceFormData, InvoiceAttachment } from "../domain/invoice";
import { convertCostToNumber } from "./costUtils";
import i18n from "../../i18n";

export const AUTOSAVE_CYCLE = 5000;

export const extractBankAccounts = (invoiceSender: CompanyDto | undefined): BankAccountDto[] => {
    if (invoiceSender) {
        return invoiceSender.bankAccounts || [];
    } else {
        return [];
    }
};

export const convertInvoiceDetailsToForm = (
    invoiceDetails: InvoiceDetailsDto | undefined
): InvoiceFormData | undefined => {
    if (!invoiceDetails) {
        return undefined;
    }

    return {
        bankAccount: invoiceDetails.bankAccount,
        correspondentFees: invoiceDetails.correspondentCosts?.toString(),
        expertFees: invoiceDetails.expertCosts?.toString(),
        invoiceDate: invoiceDetails.invoiceDate?.toString(),
        invoiceDueDate: invoiceDetails.invoiceDueDate?.toString(),
        invoiceAttachment: convertAttachmentToInvoiceAttachment(invoiceDetails.attachment),
        invoiceReference: invoiceDetails.invoiceNumber?.toString(),
        lastInvoice: invoiceDetails.isLastInvoice?.toString(),
        lawyerExpenses: invoiceDetails.lawyerExpenses?.toString(),
        lawyerFees: invoiceDetails.lawyerFees?.toString(),
        surveyorFees: invoiceDetails.surveyorCosts?.toString(),
        totalAmountLocalTaxes: invoiceDetails.totalLocalTax?.toString(),
        totalAmountVAT: invoiceDetails.totalVatAmount?.toString(),
        totalConfirmation: invoiceDetails.correctAmountVerified,
        otherExpenses: invoiceDetails.otherExpenses?.toString(),
        advanceOfFunds: invoiceDetails.advanceOfFunds?.toString(),
        previousAdvanceOfFunds: invoiceDetails.previousAdvanceOfFunds?.toString(),
        hasAdvanceOfFunds: !isNullOrUndefined(invoiceDetails.advanceOfFunds)
            || !isNullOrUndefined(invoiceDetails.previousAdvanceOfFunds),
        addressConfirmation: false,
        personalSensitiveConfirmation: false
    };
};

export const convertFormToInvoiceDetails = (
    invoiceId: string,
    form: InvoiceFormData,
    bankAccount?: BankAccountDto
): InvoiceDetailsDto => {
    return {
        bankAccount,
        id: invoiceId,
        invoiceDate: form.invoiceDate?.toString(),
        invoiceDueDate: form.invoiceDueDate?.toString(),
        invoiceNumber: form.invoiceReference,
        correspondentCosts: convertCostToNumber(form.correspondentFees),
        surveyorCosts: convertCostToNumber(form.surveyorFees),
        expertCosts: convertCostToNumber(form.expertFees),
        lawyerFees: convertCostToNumber(form.lawyerFees),
        lawyerExpenses: convertCostToNumber(form.lawyerExpenses),
        totalVatAmount: convertCostToNumber(form.totalAmountVAT),
        totalLocalTax: convertCostToNumber(form.totalAmountLocalTaxes),
        otherExpenses: convertCostToNumber(form.otherExpenses),
        advanceOfFunds: convertCostToNumber(form.advanceOfFunds),
        previousAdvanceOfFunds: convertCostToNumber(form.previousAdvanceOfFunds),
        correctAmountVerified: form.totalConfirmation,
        isLastInvoice: Boolean(form.lastInvoice)
    };
};

export const convertAttachmentToInvoiceAttachment = (
    attachment: AttachmentDto | undefined
): InvoiceAttachment | undefined => {
    if (!attachment) {
        return undefined;
    }

    return {
        filename: attachment.filename,
        fileSize: attachment.fileSize,
        fileExtension: attachment.fileExtension,
        docId: attachment?.docId,
        isUploaded: true,
        isUploading: false,
        uploadFailed: false
    };
};

type StatusBadgeProps = {
    id: string;
    invoiceStatus: string;
    submittedDate?: string;
    size?: string;
};

export const StatusBadge = ({ id, invoiceStatus, submittedDate, size = "md" }: StatusBadgeProps) => {
    switch (InvoiceStatus[invoiceStatus as keyof typeof InvoiceStatus]) {
        case InvoiceStatus.Draft:
            return <Badge
                id={id}
                variant="lightorange"
                textTransform="none"
                size={size}
            >{i18n.t("invoices:invoiceStatuses.draft")}</Badge>;
        case InvoiceStatus.Rejected:
            return <Badge
                id={id}
                variant="red"
                textTransform="none"
                size={size}
            >{ i18n.t("invoices:invoiceStatuses.rejected")}</Badge>;
        case InvoiceStatus.Submitted:
            return (
                <Tooltip
                    size="md"
                    hasArrow
                    placement="top"
                    label={"Submitted " + submittedDate}
                >
                    <Badge id={id} variant="lightgreen" textTransform="none" size={size}>
                        {i18n.t("invoices:invoiceStatuses.submitted")}
                    </Badge>
                </Tooltip>
            );
        case InvoiceStatus.InProcess:
            return <Badge id={id} variant="lightgreen" textTransform="none" size={size}>
                {i18n.t("invoices:invoiceStatuses.inProcess")}
            </Badge>;
        case InvoiceStatus.Approved:
            return <Badge id={id} variant="darkgreen" textTransform="none" size={size}>
                {i18n.t("invoices:invoiceStatuses.approved")}
            </Badge>;
        default:
            return <Badge id={id} textTransform="none" size={size}>{invoiceStatus}</Badge>;
    }
};

export const formatCostText = (cost: number, currency: string | undefined): string => {
    return `${cost.toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    })} ${currency}`;
};

const isNullOrUndefined = (value: unknown): boolean => {
    return value === null || value === undefined;
};
