import { Flex, Icon, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FiUser } from "react-icons/fi";

type ClaimsHandlerProps = {
    claimHandlerName: string;
    claimHandlerEmail: string;
};

export const ClaimsHandler = (
    { claimHandlerName, claimHandlerEmail }: ClaimsHandlerProps
) => {
    const { t } = useTranslation("claimsInvoicesOverview");

    const claimHandler = () => {
        if (claimHandlerEmail) {
            return (
                <Link data-testid={"claimHandlerId"} color="blue" href={"mailto:" + claimHandlerEmail}>
                    {claimHandlerEmail}
                </Link>
            );
        }

        if (claimHandlerName) {
            return <Text data-testid={"claimHandlerId"}>{claimHandlerName}</Text>;
        }

        return <Text data-testid={"claimHandlerId"}>{t("claimsHandler.noData")}</Text>;
    };

    return (
        <Flex gap="0.5rem" _hover={{ cursor: "pointer" }} alignItems="center">
            <Icon as={FiUser} />
            <Text>{t("claimsHandler.label")}:</Text>
            {claimHandler()}
        </Flex>
    );
};
