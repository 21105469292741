import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type UnsavedChangesModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const UnsavedChangesModal = ({
    isOpen,
    onClose
}: UnsavedChangesModalProps) => {
    const { t } = useTranslation("invoices");

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="sm"
            isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>{t("unsavedChanges.title")}</ModalHeader>
                <ModalBody>
                    <Text>{t("unsavedChanges.description")}</Text>
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" mr={3} onClick={onClose}>
                        {t("closeBtn")}
                    </Button>
                    <Button variant="primary" onClick={onClose}>
                        {t("confirmBtn")}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
