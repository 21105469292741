import { Box, Divider, Flex, Grid, GridItem, Heading, IconButton, SimpleGrid, Text, VisuallyHidden } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

import { StatusBadge } from "../../utils/invoiceUtils";
import { InvoiceSummaryDto } from "../../domain/invoice";
import { getAttachment } from "../../api/invoiceApi";
import { getTotalAmount } from "../../utils/costUtils";
import { createInvoiceAddressText } from "../../utils/addressUtils";
import { DownloadIcon } from "@digital-services-gard-as/anchor-chakra";
import { formatBytes } from "../../utils/fileUtils";

export type InvoiceReceiptProps = {
    invoiceSummary: InvoiceSummaryDto
}

const FeesText = ({ label, value, testId }: { label: string, value: string, testId: string }) => {
    if (!value) {
        return null;
    }
    return (
        <Text data-testid={testId}>
            <b>{label}:</b> {value}
        </Text>
    );
};

const InvoiceReceipt = ({ invoiceSummary }: InvoiceReceiptProps) => {
    const { t } = useTranslation("invoices");

    const transformValueToCost = (value: number | undefined) => {
        if (!value) {
            return "";
        }
        const cost = value.toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        });
        const currency = invoiceSummary.invoiceDetails?.bankAccount?.currency;
        return cost + " " + currency;
    };

    const correspondentCosts = transformValueToCost(invoiceSummary.invoiceDetails?.correspondentCosts);
    const surveyorFees = transformValueToCost(invoiceSummary.invoiceDetails?.surveyorCosts);
    const expertFees = transformValueToCost(invoiceSummary.invoiceDetails?.expertCosts);
    const lawyerFees = transformValueToCost(invoiceSummary.invoiceDetails?.lawyerFees);
    const lawyerExpenses = transformValueToCost(invoiceSummary.invoiceDetails?.lawyerExpenses);
    const totalAmountVAT = transformValueToCost(invoiceSummary.invoiceDetails?.totalVatAmount);
    const totalAmountLocalTaxes = transformValueToCost(invoiceSummary.invoiceDetails?.totalLocalTax);
    const otherExpenses = transformValueToCost(invoiceSummary.invoiceDetails?.otherExpenses);
    const totalAmount = transformValueToCost(getTotalAmount(invoiceSummary.invoiceDetails));
    const bankAccount = `${invoiceSummary.invoiceDetails?.bankAccount?.accountNumber} `
        + `(${invoiceSummary.invoiceDetails?.bankAccount?.currency})`;
    const advanceOfFunds = transformValueToCost(invoiceSummary.invoiceDetails?.advanceOfFunds);
    const previousAdvanceOfFunds = transformValueToCost(invoiceSummary.invoiceDetails?.previousAdvanceOfFunds);

    const handleAttachmentClick = useCallback(() => {
        getAttachment(invoiceSummary.invoiceId, invoiceSummary.invoiceDetails?.attachment?.docId);
    }, [invoiceSummary.invoiceDetails?.attachment?.docId, invoiceSummary.invoiceId]);

    return (
        <Flex flexDir="column" justifyContent="center" gap="2.5rem" mb="5rem">
            <Flex flexDir="column" justifyContent="center">
                <Heading size="xl" textAlign="center" id="createInvoiceTitle">
                    {t("invoiceReceipt.title")}
                </Heading>
                <Box mt="1rem" textAlign="center" id="invoiceStatus">
                    <StatusBadge id="statusBadge" invoiceStatus={invoiceSummary.status} />
                </Box>
            </Flex>
            <Flex flexDir="column" alignItems="center" gap="1rem">
                { /*}
                <Flex
                    id="downloadReceipt"
                    alignItems="center"
                    justifyContent="end"
                    w="100%"
                    maxW="50rem"
                    gap="0.5rem"
                    textDecoration="underline"
                    _hover={{ color: "blue" }}
                >
                    <Text>{t("invoiceReceipt.downloadReceipt")}</Text>
                    <Icon as={DownloadIcon} />
                </Flex>
                */}
                <Flex
                    backgroundColor="white"
                    flexDir="column"
                    w={{ base: "100%", lg: "50rem" }}
                    boxShadow={"md"}
                >
                    <Flex flexDir="column" p="2.5rem 1.5rem" justify="space-between">
                        <Heading size="lg" data-testid="submissionReceiptTitle">
                            {t("invoiceReceipt.submissionReceipt")}
                        </Heading>
                        <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(3, 1fr)' mt="1.5rem">
                            <GridItem>
                                <Text data-testid="submissionDate" as="b">{t("invoiceReceipt.submissionDate")}</Text>
                                <Text data-testid="submissionDateVal">
                                    {invoiceSummary.invoiceDetails?.submittedDate}
                                </Text>
                            </GridItem>
                            <GridItem>
                                <Text data-testid="invoiceFrom" as="b">{t("invoiceReceipt.invoiceFrom")}</Text>
                                <Text data-testid="invoiceFromVal">{invoiceSummary.invoiceSender?.name}</Text>
                            </GridItem>
                            <GridItem rowSpan={2}>
                                <Text data-testid="invoiceTo" as="b">{t("invoiceReceipt.invoiceTo")}</Text>
                                <Text whiteSpace="pre-wrap" data-testid="invoiceToVal">
                                    {createInvoiceAddressText(invoiceSummary.invoiceRecipient)}
                                </Text>
                            </GridItem>
                            <GridItem>
                                <Text data-testid="invoiceDate" as="b">{t("invoiceReceipt.invoiceDate")}</Text>
                                <Text data-testid="invoiceDateVal">{invoiceSummary.invoiceDetails?.invoiceDate}</Text>
                            </GridItem>
                            <GridItem colStart={2}>
                                <Text data-testid="clientName" as="b">{t("invoiceReceipt.clientName")}</Text>
                                <Text data-testid="clientNameVal">{invoiceSummary.claimDetails?.clientName}</Text>
                            </GridItem>
                            {invoiceSummary.invoiceSender?.hierarchy?.companyName && (
                                <GridItem colStart={2}>
                                    <Text data-testid="branchCompany" as="b">{t("invoiceReceipt.branchCompany")}</Text>
                                    <Text whiteSpace="pre-wrap" data-testid="branchCompanyVal" fontSize={14}>
                                        {invoiceSummary.invoiceSender?.hierarchy?.companyName}
                                    </Text>
                                </GridItem>
                            )}
                            <GridItem>
                                <Text data-testid="invoiceDueDate" as="b">{t("invoiceReceipt.invoiceDueDate")}</Text>
                                <Text data-testid="invoiceDueDateVal">
                                    {invoiceSummary.invoiceDetails?.invoiceDueDate}</Text>
                            </GridItem>
                        </Grid>
                    </Flex>
                    { /* Needs to be changed with anchor.navy.10 */ }
                    <Flex backgroundColor="anchor.gray.50" flexDir="column" p="2.5rem 2rem" gap="0.5rem">
                        <Heading size="md">{invoiceSummary.claimDetails?.description}</Heading>
                        <Text mt="1rem" data-testid="vessel">
                            <b>{t("invoiceReceipt.vessel")}:</b> {invoiceSummary.claimDetails?.vessel}
                        </Text>
                        <Text data-testid="incidentDate">
                            <b>{t("invoiceReceipt.incidentDate")}:</b> {invoiceSummary.invoiceDetails?.incidentDate}
                        </Text>
                        <Text data-testid="subClaimsRef" whiteSpace="pre-wrap">
                            <b>{t("invoiceReceipt.subClaimsRef")}:</b> {invoiceSummary.invoiceDetails?.subClaimsRef}
                        </Text>
                        <Text data-testid="yourInvoiceRef" whiteSpace="pre-wrap">
                            <b>{t("invoiceReceipt.yourInvoiceRef")}:</b> {invoiceSummary.invoiceDetails?.invoiceNumber}
                        </Text>
                        <Text data-testid="gardClaimRef" whiteSpace="pre-wrap">
                            <b>{t("invoiceReceipt.gardClaimRef")}:</b> {invoiceSummary.claimDetails?.id}
                        </Text>

                        <Divider colorScheme="blackAlpha" m="1rem" />

                        <SimpleGrid columns={{ base: 1, lg: 3 }}>
                            <Flex flexDir="column" gap="0.5rem">
                                <Text data-testid="feesAndExpenses" as="b">
                                    {t("invoiceReceipt.feesAndExpenses")}
                                </Text>
                                <FeesText label={t("invoiceReceipt.correspondentFees") as string}
                                    value={correspondentCosts}
                                    testId="correspondentFees"
                                />
                                <FeesText label={t("invoiceReceipt.surveyorFees") as string}
                                    value={surveyorFees}
                                    testId="surveyorFees"
                                />
                                <FeesText label={t("invoiceReceipt.expertFees") as string}
                                    value={expertFees}
                                    testId="expertFees"
                                />
                                <FeesText label={t("invoiceReceipt.lawyerFees") as string}
                                    value={lawyerFees}
                                    testId="lawyerFees"
                                />
                                <FeesText label={t("invoiceReceipt.lawyerExpenses") as string}
                                    value={lawyerExpenses}
                                    testId="lawyerExpenses"
                                />
                                <FeesText label={t("invoiceReceipt.totalAmountVAT") as string}
                                    value={totalAmountVAT}
                                    testId="totalAmountVAT"
                                />
                                <FeesText label={t("invoiceReceipt.totalAmountLocalTaxes") as string}
                                    value={totalAmountLocalTaxes}
                                    testId="totalAmountLocalTaxes"
                                />
                                <FeesText label={t("invoiceReceipt.otherExpenses") as string}
                                    value={otherExpenses}
                                    testId="otherExpenses"
                                />
                                <FeesText label={t("invoiceReceipt.advanceOfFunds") as string}
                                    value={advanceOfFunds}
                                    testId="advanceOfFunds"
                                />
                                <FeesText label={t("invoiceReceipt.previousAdvanceOfFunds") as string}
                                    value={previousAdvanceOfFunds}
                                    testId="previousAdvanceOfFunds"
                                />
                            </Flex>
                            <Flex>
                                <VisuallyHidden />
                            </Flex>
                            <Flex flexDir="column" justifyContent="end" w="100%" h="100%" mt={{ base: "2rem", lg: 0 }}>
                                <Text data-testid="totalFeesExpensesTaxes">
                                    {t("invoiceReceipt.totalFeesExpensesTaxes")}:
                                </Text>
                                <Heading size="sm" as="b" data-testid="totalAmount">{totalAmount}</Heading>
                                <Text as="b" mt="1rem" data-testid="toBankAccount">
                                    {t("invoiceReceipt.toBankAccount")}
                                </Text>
                                <Text data-testid="bankAccount">{bankAccount}</Text>
                            </Flex>
                        </SimpleGrid>
                    </Flex>
                </Flex>
                { invoiceSummary.invoiceDetails?.attachment && (
                    <Flex justifyContent="center" gap={3} mt="3rem" flexDir="column" w="100%" maxW="50rem">
                        <Heading size="lg" data-testid="attachments">{t("invoiceReceipt.attachments")}:</Heading>
                        <Flex
                            gap="0.5rem"
                            alignItems="center"
                            onClick={handleAttachmentClick}
                            _hover={{ color: "blue" }}
                            w="100%"
                            justify="space-between"
                            bg="anchor.white"
                            p="1rem"
                            rounded="lg"
                            border="2px solid"
                            borderColor="anchor.brown.100"
                        >
                            <Flex gap="1rem">
                                <Text data-testid="filename" textDecoration="underline" as="b">
                                    {invoiceSummary.invoiceDetails?.attachment?.filename}
                                    .{invoiceSummary.invoiceDetails?.attachment?.fileExtension}
                                </Text>
                                <Text>
                                    {invoiceSummary.invoiceDetails?.attachment?.fileSize}
                                    {formatBytes(invoiceSummary.invoiceDetails?.attachment?.fileSize)}
                                </Text>
                            </Flex>
                            <IconButton
                                icon={ <DownloadIcon /> }
                                variant="secondary"
                                aria-label={
                                    "Download attachment"
                                    + invoiceSummary.invoiceDetails?.attachment?.filename
                                }
                                onClick={handleAttachmentClick}
                            />
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

export default InvoiceReceipt;
