import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Radio, RadioGroup, Spinner, Text, VStack } from "@chakra-ui/react";
import { ChangeEvent, MutableRefObject, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClaimsDetailsDto } from "../../../api/claimsApi";

type SubclaimsModalProps = {
    isOpen: boolean,
    claimIdRef: MutableRefObject<string>,
    isCreatingInvoice: boolean,
    claimsInfo: ClaimsDetailsDto | undefined,
    reset: () => void,
    onClose: () => void,
    onCreateInvoiceClick: (subClaimReference: string) => void
}

const SubclaimsModal = ({
    isOpen,
    claimIdRef,
    isCreatingInvoice,
    claimsInfo,
    reset,
    onClose,
    onCreateInvoiceClick
}: SubclaimsModalProps) => {
    const { t } = useTranslation(["claimsInvoicesOverview", "errorMessages", "main"]);
    const [subClaimReference, setSubClaimReference] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string | undefined | null>();

    const handleNextClick = useCallback(() => {
        if (subClaimReference) {
            onCreateInvoiceClick(subClaimReference);
        } else {
            setErrorMessage(t("errorMessages:subClaimsModal.selectSubClaim"));
        }
    }, [onCreateInvoiceClick, subClaimReference, t]);

    const handleCancelClick = useCallback(() => {
        reset();
        setErrorMessage("");
        claimIdRef.current = "";
        onClose();
    }, [claimIdRef, onClose, reset]);

    const handleRadioChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSubClaimReference(e.target.value);
    }, []);

    const mainContent = () => {
        return (
            <Flex justifyContent="center" flexDir="column">
                { claimsInfo?.subClaims && (
                    <Flex flexDir="column" justifyContent="left">
                        <RadioGroup value={subClaimReference}>
                            <VStack alignItems="left">
                                { claimsInfo?.subClaims.map((subClaim, index) => (
                                    <Radio
                                        id={subClaim.id}
                                        key={subClaim.id}
                                        value={subClaim.gardSubClaimRef}
                                        onChange={handleRadioChange}
                                    >{index + 1} - {subClaim.description}</Radio>
                                ))}
                            </VStack>
                        </RadioGroup>
                        <Button
                            id="confirmBtn"
                            w="100%"
                            mt="1rem"
                            onClick={handleNextClick}
                        >{isCreatingInvoice ? <Spinner /> : t("main:next")}</Button>
                        { errorMessage && (
                            <Text color="red">{errorMessage}</Text>
                        )}
                    </Flex>
                )}
                { !claimsInfo?.subClaims && (
                    <Text color="red">{t("errorMessages:subClaimsModal.noSubclaims")}</Text>
                )}
            </Flex>
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} id="subclaimModal">
            <ModalOverlay p="1rem" />
            <ModalContent p="1rem">
                <ModalHeader textAlign="left">
                    {t("claimsInvoicesOverview:subClaimsModal.title")}
                </ModalHeader>
                <ModalCloseButton id="subclaimsModalClose" />
                <ModalBody>
                    <Flex flexDir="column" gap="1rem">
                        { mainContent() }
                        <Button
                            id="cancelBtn"
                            w="100%"
                            variant="ghost"
                            onClick={handleCancelClick}
                        >{t("main:cancel")}</Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default SubclaimsModal;
