import i18next from "i18next";
import { ErrorCode } from "react-dropzone";

// eslint-disable-next-line no-magic-numbers
export const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const createFileErrorMessage = (filename: string, error: string) => {
    switch (error) {
        case ErrorCode.FileInvalidType:
            return i18next.t("errorMessages:invoiceForm.fileUploader.invalidFiletype", { filename });
        case ErrorCode.FileTooLarge:
            return i18next.t("errorMessages:invoiceForm.fileUploader.fileTooLarge", { filename });
        case ErrorCode.FileTooSmall:
            return i18next.t("errorMessages:invoiceForm.fileUploader.fileTooSmall", { filename });
        case ErrorCode.TooManyFiles:
            return i18next.t("errorMessages:invoiceForm.fileUploader.tooManyFiles", { filename });
        default:
            return i18next.t("errorMessages:invoiceForm.fileUploader.defaultFileError", { filename });
    }
};
