import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";


type CurrencyChangeModalProps = {
    isOpen: boolean,
    onClose: () => void
}

export const CurrencyChangeModal = ({ isOpen, onClose }: CurrencyChangeModalProps) => {
    const { t } = useTranslation("invoices");

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>{t("changeCurrencyHeader")}</ModalHeader>
                <ModalBody>
                    <Text>{t("changeCurrencyMsg")}</Text>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        {t("closeBtn")}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
