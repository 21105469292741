import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider, extendTheme, ThemeConfig } from "@chakra-ui/react";
import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import theme from "@digital-services-gard-as/anchor-chakra";
import { FeatureFlags, FlagsProvider } from "flagged";

import App from "./App";
import "../i18n";
import "./index.css";
import { ErrorPage } from "./components/ErrorPage";

const anchorTheme: ThemeConfig = extendTheme(theme, {
    styles: {
        global: {
            body: {
                bg: "#F8F7F6"
            }
        }
    }
});

const reactPlugin = new ReactPlugin();

const extractFeatureFlags = (): FeatureFlags => {
    const sortListOfClaimsFlag = import.meta.env.VITE_FEATURE_SORT_LIST_OF_CLAIMS;
    const sortListOfInvoicesFlag = import.meta.env.VITE_FEATURE_SORT_LIST_OF_INVOICES;
    const exportInvoicesFlag = import.meta.env.VITE_FEATURE_EXPORT_INVOICES;
    const hierarchicalCompaniesFlag = import.meta.env.VITE_FEATURE_HIERARCHICAL_COMPANIES;
    const invoiceRejectionMessageFlag = import.meta.env.VITE_FEATURE_INVOICE_REJECTION_MESSAGE;
    const registerInvoicesSubcompanyFlag = import.meta.env.VITE_FEATURE_REGISTER_INVOICES_SUBCOMPANY;

    return {
        "CIA-566-SortListOfClaims": sortListOfClaimsFlag === "true",
        "CIA-567-SortListOfInvoices": sortListOfInvoicesFlag === "true",
        "CIA-989-ExportInvoices": exportInvoicesFlag === "true",
        "CIA-322-HierarchicalCompanies": hierarchicalCompaniesFlag === "true",
        "CIA-906-InvoiceRejectionMessage": invoiceRejectionMessageFlag === "true",
        "CIA-1097-RegisterInvoicesSubcompany": registerInvoicesSubcompanyFlag === "true"
    };
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <AppInsightsContext.Provider value={reactPlugin}>
            <ChakraProvider theme={anchorTheme}>
                <FlagsProvider features={extractFeatureFlags()}>
                    <AppInsightsErrorBoundary
                        onError={ErrorPage}
                        appInsights={reactPlugin}
                    >
                        <App />
                    </AppInsightsErrorBoundary>
                </FlagsProvider>
            </ChakraProvider>
        </AppInsightsContext.Provider>
    </React.StrictMode>
);
