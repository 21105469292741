import {
    Alert,
    AlertIcon,
    Box,
    Flex,
    Heading,
    Link,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import { ClaimsOverview } from "./claims/ClaimsOverview";
import ExternalLanding from "./ExternalLanding";
import { InvoicesOverview } from "./invoices/InvoicesOverview";
import { CustomAttributes, attributesIsCIAFlags } from "../auth/customAttributes";

export const Home = () => {
    const auth = useAuth();
    const { t } = useTranslation(["claimsInvoicesOverview", "errorMessages"]);
    const [attributes, setAttributes] = useState<CustomAttributes | undefined>();

    useEffect(() => {
        if (auth.user?.profile?.custom_attributes) {
            const isAttributes = attributesIsCIAFlags(auth.user?.profile?.custom_attributes);

            if (!isAttributes) {
                throw new Error("Attributes are not of type CustomAttributes");
            }
            setAttributes(auth.user?.profile?.custom_attributes as CustomAttributes);
        }
    }, [auth.user?.profile, setAttributes]);

    if (auth.error) {
        throw new Error("An error occurred while trying to authenticate the user.");
    }

    if (!auth.isAuthenticated) {
        return (
            <ExternalLanding />
        );
    }


    const showContent = () => {
        if (attributes && (attributes.CIA_enabled_contact !== "true"
        || attributes.CIA_enabled_company !== "true")) {
            return (
                <Alert status="info" maxW="30rem" textColor="white" id="missingAccessAlert">
                    <AlertIcon />
                    <Box>
                        {t("claimsInvoicesOverview:missingAccess")}
                        <br />
                        <Link href="mailto:claims.invoicing@gard.no">claims.invoicing@gard.no</Link>
                    </Box>
                </Alert>
            );
        }

        return (
            <Flex
                backgroundColor="white"
                m={{ base: "0", md: "0 2.5rem" }}
                mb="4rem"
                maxW="full"
                justifyContent="center"
                boxShadow="0px 1px 2px 0px #0000000F"
            >
                <Flex m="3rem 0" justifyContent="center">
                    <Tabs align="center" w="100%" isLazy defaultIndex={0} tabIndex={-1}>
                        <TabList w="10rem" id="tablist">
                            <Tab data-testid="tabClaims" _selected={{
                                borderBottom: "4px solid",
                                borderBottomColor: "anchor.clearBlue.50"
                            }}>
                                {t("claimsInvoicesOverview:tabClaims")}
                            </Tab>
                            <Tab data-testid="tabInvoices" _selected={{
                                borderBottom: "4px solid",
                                borderBottomColor: "anchor.clearBlue.50"
                            }}>
                                {t("claimsInvoicesOverview:tabInvoices")}
                            </Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel pb="0" tabIndex={-1}>
                                <ClaimsOverview />
                            </TabPanel>
                            <TabPanel pb="0" tabIndex={-1}>
                                <InvoicesOverview />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Flex>
            </Flex>
        );
    };

    return (
        <Flex flexDir="column" alignItems="center" maxH="100vh">
                <Heading m="4rem 0" id="claimsInvoicesOverviewTitle">{t("claimsInvoicesOverview:title")}</Heading>
                {showContent()}
        </Flex>
    );
};
