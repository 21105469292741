import { t } from "i18next";
import { useState } from "react";
import { FormState, SubmitErrorHandler, SubmitHandler, UseFormSetError } from "react-hook-form";

import { InvoiceFormData, InvoiceDetailsDto, BankAccountDto, InvoiceSummaryDto } from "../domain/invoice";
import { convertFormToInvoiceDetails } from "../utils/invoiceUtils";

type UseSubmitProps = {
    totalAmount: number,
    formValues: InvoiceFormData,
    bankAccounts: BankAccountDto[],
    invoiceSummary: InvoiceSummaryDto,
    formState: FormState<InvoiceFormData>,
    setError: UseFormSetError<InvoiceFormData>,
    submitInvoice: (props: { id: string, invoiceDetails: InvoiceDetailsDto }) => void
}

export const useSubmit = ({
    totalAmount,
    formValues,
    bankAccounts,
    invoiceSummary,
    formState,
    setError,
    submitInvoice
}: UseSubmitProps) => {
    const [submitError, setSubmitError] = useState("");

    const onSubmit: SubmitHandler<InvoiceFormData> = _data => {
        let error = false;
        if (totalAmount === 0) {
            setSubmitError(t("errorMessages:invoiceForm.missingCost") as string);
            error = true;
        }
        // TODO: Remove this check and validate attachments in validation.ts instead.
        if (!formValues.invoiceAttachment) {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            setError("invoiceAttachment", {
                message: t("errorMessages:invoiceForm.missingInvoiceFiles") as string,
                type: "custom"
            });
            error = true;
        }

        if (error) {
            return;
        }

        if((invoiceSummary.invoiceRecipient?.vatNumber === undefined
            || invoiceSummary.invoiceRecipient?.vatNumber === "")
            && formValues.totalAmountVAT !== undefined) {
            setError("totalAmountVAT", {
                message: "Recipient is not VAT Applicable",
                type: "custom"
            });
            return;
        }

        setSubmitError("");
        const bankAccount = bankAccounts.filter(e => e.accountNumber === formValues.bankAccount?.accountNumber
            && e.currency === formValues.bankAccount?.currency)[0];
        const invoiceDetails: InvoiceDetailsDto = convertFormToInvoiceDetails(
            invoiceSummary.invoiceId, formValues, bankAccount
        );
        submitInvoice({
            id: invoiceSummary.invoiceId,
            invoiceDetails
        });
    };

    const onErrorSubmit: SubmitErrorHandler<InvoiceFormData> = _data => {
        if (!formState.isValid) {
            const amountOfErrors = Object.keys(formState.errors).length;
            setSubmitError(amountOfErrors === 0 ? ""
                : t("errorMessages:invoiceForm.errorAmount", { amount: amountOfErrors }) as string);
        }

        if (totalAmount === 0) {
            setSubmitError(t("errorMessages:invoiceForm.missingCost") as string);
        }

        if (!formValues.invoiceAttachment) {
            setError("invoiceAttachment", {
                message: t("errorMessages:invoiceForm.missingInvoiceFiles") as string,
                type: "custom"
            });
        }
    };

    return {
        onSubmit,
        onErrorSubmit,
        submitError,
        setSubmitError
    };
};
