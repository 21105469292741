import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from "@chakra-ui/react";
import { t } from "i18next";
import { MutableRefObject, useCallback } from "react";

type DeleteInvoiceModalProps = {
    invoiceId: string,
    isDeleteModalOpen: boolean,
    cancelRef: MutableRefObject<HTMLButtonElement | null>,
    onCloseDeleteModal: () => void,
    deleteInvoice: (invoiceId: string) => void;
}

export const DeleteInvoiceModal = ({
    invoiceId,
    isDeleteModalOpen,
    cancelRef,
    onCloseDeleteModal,
    deleteInvoice
}: DeleteInvoiceModalProps) => {
    const handleDeleteClick = useCallback(() => {
        deleteInvoice(invoiceId);
        onCloseDeleteModal();
    }, [deleteInvoice, invoiceId, onCloseDeleteModal]);

    return (
        <AlertDialog
            isOpen={isDeleteModalOpen}
            leastDestructiveRef={cancelRef}
            onClose={onCloseDeleteModal}
            id="deleteInvoiceModal"
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {t("invoices:deleteInvoice")}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        {t("invoices:createInvoice.deleteInvoiceMsg")}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            id="closeBtn"
                            ref={cancelRef}
                            onClick={onCloseDeleteModal}
                            variant="outline"
                        >
                            {t("invoices:closeBtn")}
                        </Button>
                        <Button
                            id="deleteBtn"
                            colorScheme="red"
                            onClick={handleDeleteClick}
                            ml={3}
                        >
                            {t("invoices:createInvoice.delete")}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
