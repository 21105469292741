import { Tr, Td } from "@chakra-ui/react";
import { Cell, Row, flexRender } from "@tanstack/react-table";
import { Fragment, useCallback } from "react";
import ClaimsDetails from "../ClaimsDetails";
import { ClaimsItemDto } from "../../../api/claimsApi";

type ClaimsTableRowProps = {
    row: Row<ClaimsItemDto>
}

const ClaimsTableRow = ({ row }: ClaimsTableRowProps) => {
    const handleKeyUp = useCallback((e: React.KeyboardEvent<HTMLTableRowElement>) => {
        if (e.key === "Enter") {
            row.getToggleExpandedHandler()();
        }
    }, [row]);

    const handleOnClick = useCallback(() => {
        if (!window.getSelection()?.toString()) {
            row.getToggleExpandedHandler()();
        }
    }, [row]);

    const handleBackgroundColor = (cell: Cell<ClaimsItemDto, unknown>) =>
        (cell.getContext().column.getIsSorted() || row.getIsExpanded()) ? "anchor.brown.100" : {};

    return (
        <Fragment key={row.id}>
            <Tr
                id={"claimsTableRow" + row.id}
                onClick={handleOnClick}
                _hover={{
                    cursor: "pointer",
                    backgroundColor: "anchor.brown.50"
                }}
                tabIndex={0}
                onKeyUp={handleKeyUp}
                maxW="100%"
            >
                {/* first row is a normal row */}
                {row.getVisibleCells().map(cell => {
                    return (
                        <Td
                            key={cell.id}
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            backgroundColor={handleBackgroundColor(cell)}
                            fontSize={"16px"}
                            wordBreak="break-word"
                        >
                            {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                            )}
                        </Td>
                    );
                })}
            </Tr>
            {row.getIsExpanded() && (
                <Tr>
                    {/* 2nd row is a custom 1 cell row */}
                    <Td colSpan={row.getVisibleCells().length} p="0" maxW="100%">
                        <ClaimsDetails claimsId={row.getValue("id")} />
                    </Td>
                </Tr>
            )}
        </Fragment>
    );
};

export default ClaimsTableRow;
