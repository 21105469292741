import { AuthProvider } from "./hooks/providers/AuthProvider";
import { configureStore } from "@reduxjs/toolkit";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";

import Layout from "./theme/Layout";
import { claimsInvoicingApi, rtkQueryAuthExpire } from "./api/claimsInvoicingApi";
import { Home } from "./home/Home";
import Signout from "./auth/Signout";
import Invoice from "./invoices/Invoice";
import authReducer from "./store/authSlice";

export function configureAppStore() {
    return configureStore({
        reducer: {
            [claimsInvoicingApi.reducerPath]: claimsInvoicingApi.reducer,
            auth: authReducer
        },
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware().concat(claimsInvoicingApi.middleware).concat(rtkQueryAuthExpire)
    });
}

const store = configureAppStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

const App = () => {
    return (
        <BrowserRouter>
            <Provider store={store}>
                <AuthProvider>
                    <Layout />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/invoice/:invoiceId" element={<Invoice />} />
                        <Route path="/signout" element={<Signout />} />
                    </Routes>
                </AuthProvider>
            </Provider>
        </BrowserRouter>
    );
};

export default App;
