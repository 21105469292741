import { Box, Checkbox, CheckboxGroup, Flex, FormControl, FormErrorMessage, Text } from "@chakra-ui/react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { InvoiceFormData, MemberDetailsDto } from "../../../../domain/invoice";
import { createInvoiceAddressText } from "../../../../utils/addressUtils";

type InvoiceConfirmationProps = {
    register: UseFormRegister<InvoiceFormData>;
    errors: FieldErrors<InvoiceFormData>;
    invoiceRecipient: MemberDetailsDto | undefined;
};

export const InvoiceConfirmation = ({
    register,
    errors,
    invoiceRecipient
}: InvoiceConfirmationProps) => {
    const { t } = useTranslation("invoices");

    return (
        <Flex flexDir="column" gap="2rem">
            <Text>{t("createInvoice.feesAndExpenses.confirmation.guidance")}</Text>
            <FormControl
                isInvalid={
                    !!errors.addressConfirmation?.message
                    || !!errors.totalConfirmation?.message
                    || !!errors.personalSensitiveConfirmation?.message
                }>
                <CheckboxGroup>
                    <Checkbox
                        {...register("addressConfirmation")}
                        isInvalid={!!errors.addressConfirmation?.message}
                        data-testid="addressConfirmation"
                    >
                        <Trans t={t} i18nKey="createInvoice.feesAndExpenses.confirmation.addressConfirmation" />
                    </Checkbox>
                    <Box bgColor="anchor.gray.50" p="1rem" w="100%" m="1rem 2em">
                        <Text whiteSpace="pre-wrap">{createInvoiceAddressText(invoiceRecipient)}</Text>
                    </Box>
                    <FormErrorMessage data-testid="addressConfirmationError">
                        {errors.addressConfirmation?.message}
                    </FormErrorMessage>
                    <Checkbox
                        {...register("totalConfirmation")}
                        isInvalid={!!errors.totalConfirmation?.message}
                        data-testid="totalConfirmation"
                    >
                        <Trans t={t} i18nKey="createInvoice.feesAndExpenses.confirmation.totalAmountMatch" />
                    </Checkbox>
                    <FormErrorMessage data-testid="totalConfirmationError">
                        {errors.totalConfirmation?.message}
                    </FormErrorMessage>
                    <Checkbox
                        {...register("personalSensitiveConfirmation")}
                        isInvalid={!!errors.personalSensitiveConfirmation?.message}
                        data-testid="personalSensitiveConfirmation"
                    >
                        <Trans
                            t={t}
                            i18nKey="createInvoice.feesAndExpenses.confirmation.personalSensitiveConfirmation"
                        />
                    </Checkbox>
                    <FormErrorMessage data-testid="personalSensitiveConfirmationError">
                        {errors.personalSensitiveConfirmation?.message}
                    </FormErrorMessage>
                </CheckboxGroup>
            </FormControl>
        </Flex>
    );
};
