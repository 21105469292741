import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Accept, DropzoneInputProps, DropzoneRootProps, FileRejection, useDropzone } from "react-dropzone";
import { Box, Flex, Icon, Link, Spinner, Text } from "@chakra-ui/react";
import { FiUploadCloud, FiTrash } from "react-icons/fi";
import { useTranslation } from "react-i18next";

import { InvoiceAttachment } from "../domain/invoice";
import { createFileErrorMessage, formatBytes } from "../utils/fileUtils";

type FileInputProps = {
    id?: string;
    name: string;
    invoiceId: string;
    onDeleteClick: () => void;
    onAttachmentClick: (invoiceId: string, docId: string | undefined) => void;
    accept: Accept | undefined,
    isInvalid: boolean
}

const FileUpload = ({ accept, name, onDeleteClick, onAttachmentClick, id, invoiceId, isInvalid }: FileInputProps) => {
    const [fileRejection, setFileRejection] = useState<FileRejection>({} as FileRejection);
    const { register, unregister, setValue, watch } = useFormContext();
    const attachment: InvoiceAttachment = watch(name);
    const onDrop = useCallback(
        (acceptedFiles: File[], rejections: FileRejection[]) => {
            if (acceptedFiles.length >= 1) {
                const files: InvoiceAttachment[] = acceptedFiles.map(file => {
                    return {
                        file,
                        filename: file.name.split(".")[0],
                        fileSize: file.size,
                        fileExtension: file.name.split(".")[1],
                        isUploaded: false,
                        isUploading: false,
                        uploadFailed: false
                    };
                });
                setValue(name, files[0], { shouldValidate: true });
            }

            setFileRejection(rejections[0]);
        },
        [setValue, name]
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept,
        multiple: false
    });
    useEffect(() => {
        register(name, { value: attachment });
        return () => {
            unregister(name);
        };
    }, [register, unregister, name, attachment]);

    return (
        <Flex flexDir="column" w="100%">
            <Dropzone
                isInvalid={isInvalid}
                id={id}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isDragActive={isDragActive}
            />
            <FileList
                attachment={attachment}
                fileRejection={fileRejection}
                invoiceId={invoiceId}
                onDeleteClick={onDeleteClick}
                onAttachmentClick={onAttachmentClick}
            />
        </Flex>
    );
};

type DropzoneProps = {
    isInvalid: boolean,
    getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T,
    getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T,
    isDragActive: boolean,
    id?: string
}

const Dropzone = ({ isInvalid, getRootProps, getInputProps, isDragActive, id }: DropzoneProps) => {
    useEffect(() => {
        if (isInvalid) {
            const input = document.getElementById("fileUpload");
            input?.focus();
        }
    }, [isInvalid, id]);
    const { t } = useTranslation("invoices");
    return (
        <Flex
            {...getRootProps()}
            id="fileUpload"
            backgroundColor="anchor.gray.100"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            padding="1rem 7rem"
            gap="0.75rem"
            w="100%"
            borderRadius="0.5rem"
            border={isInvalid ? "1px solid red" : {}}
        >
            <Icon
                as={FiUploadCloud}
                color="anchor.navy.400"
                textAlign="center"
                boxSize="24px"
            />
            <input id={id} {...getInputProps()} type="file" multiple={true} />
            {isDragActive ? (
                <p>{t("createInvoice.fileUpload.dropHere")}</p>
            ) : (
                <Box textAlign="center">
                    <Text fontSize="16px">
                        <b>{t("createInvoice.fileUpload.clickToUpload")}</b>
                        {t("createInvoice.fileUpload.dragAndDrop")}
                    </Text>
                    <Text fontSize="14px">
                        {t("createInvoice.fileUpload.allowedFiletypes")}
                    </Text>
                </Box>
            )}
        </Flex>
    );
};

type FileListProps = {
    attachment: InvoiceAttachment | undefined;
    fileRejection: FileRejection;
    invoiceId: string;
    onDeleteClick: () => void;
    onAttachmentClick: (invoiceId: string, docId: string | undefined) => void;
};

const FileList = ({ attachment, fileRejection, onDeleteClick, onAttachmentClick, invoiceId }: FileListProps) => {
    const { t } = useTranslation("errorMessages");
    const handleAttachmentClick = useCallback(() => {
        onAttachmentClick(invoiceId, attachment?.docId);
    }, [onAttachmentClick, invoiceId, attachment]);

    const showAttachment = () => {
        if (attachment?.isUploading) {
            return (
                <Flex alignItems="center" gap="0.5rem">
                    <Text>{attachment.filename} ({formatBytes(attachment.fileSize)})</Text>
                    <Spinner size="sm" />
                </Flex>
            );
        }

        if (attachment?.isUploaded) {
            return (
                <Flex key={attachment.filename} alignItems="center" gap="0.5rem">
                    <Link
                        id="attachmentLink"
                        key={attachment.filename}
                        onClick={handleAttachmentClick}
                    >
                        <Text as="u" color="blue">{attachment.filename}.{attachment.fileExtension}</Text> (
                        {formatBytes(attachment.fileSize)})
                    </Link>
                    <Icon
                        id="deleteAttachmentBtn"
                        color="blue"
                        as={FiTrash}
                        onClick={onDeleteClick}
                        _hover={{ cursor: "pointer" }}
                    />
                </Flex>
            );
        }

        if (attachment?.uploadFailed) {
            return <Text color="anchor.red.500" id="uploadFailed">{t("invoiceForm.fileUploader.uploadFailed", {
                attachment: `${attachment?.filename}.${attachment?.fileExtension}`,
                attachmentSize: formatBytes(attachment?.fileSize)
            })}</Text>;
        }

        return null;
    };

    return (
        <Flex flexDir="column" mt="1rem" gap="0.5rem">
                <FileErrors rejection={fileRejection} />
            {showAttachment()}
        </Flex>
    );
};

const FileErrors = ({ rejection }: { rejection: FileRejection }) => {
    return (
        <Flex flexDir="column">
            {rejection?.errors?.map(error => (
                <Text color="anchor.red.500" key={error.code} id="fileError">
                    {createFileErrorMessage(rejection.file.name, error.code)}
                </Text>
            ))}
        </Flex>
    );
};

export default FileUpload;
