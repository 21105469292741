import { Flex, InputGroup, Input, InputRightElement, IconButton, InputLeftElement, Spinner } from "@chakra-ui/react";

import { QueryFilter } from "../api/claimsInvoicingApi";
import { CloseIcon, SearchIcon } from "@digital-services-gard-as/anchor-chakra";

type SearchProps = {
    type: "claims" | "invoices";
    placeholder: string;
    queryFilter: QueryFilter;
    isFetchingData: boolean;
    handleSearchCriteriaChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    handleSearchClick: () => void;
    handleClearSearch: () => void;
}

export const Search = ({
    type,
    placeholder,
    queryFilter,
    isFetchingData,
    handleSearchCriteriaChange,
    handleKeyUp,
    handleSearchClick,
    handleClearSearch
}: SearchProps) => {
    return (
        <Flex w="100%" justifyContent="center" alignItems="center" h="fit-content">
            <InputGroup variant="search" size="lg" maxW="40rem">
                <InputLeftElement>
                    <IconButton
                        variant={"none"}
                        aria-label={"Search " + type}
                        data-testid={type + "SearchBtn"}
                        icon={<SearchIcon size="sm" />}
                        onClick={handleSearchClick}
                        tabIndex={0}
                        disabled={isFetchingData}
                    />
                </InputLeftElement>
                <Input
                    data-testid={type + "Search"}
                    type="text"
                    placeholder={placeholder}
                    value={queryFilter.query}
                    onChange={handleSearchCriteriaChange}
                    onKeyUp={handleKeyUp}
                    tabIndex={0}
                    bg="anchor.brown.50"
                    color="anchor.gray.600"
                    fontSize="15px"
                    h="fit-content"
                />
                <InputRightElement w="6rem">
                    {queryFilter.query && (
                        <IconButton
                            data-testid={type + "ClearSearchBtn"}
                            aria-label="Clear search" variant="none" onClick={handleClearSearch}>
                            <CloseIcon />
                        </IconButton>
                    )}
                    {isFetchingData && (
                        <Spinner size="sm" />
                    )}
                </InputRightElement>
            </InputGroup>
        </Flex>
    );
};
