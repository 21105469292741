import React, { useEffect } from "react";


const usePrevious = <T>(value: T): T | undefined => {
    const ref = React.useRef<T>();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

export default usePrevious;
