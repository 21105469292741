import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuthExpired: false
    },
    reducers: {
        login: state => {
            state.isAuthExpired = false;
        },
        logout: state => {
            state.isAuthExpired = true;
        }
    }
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
