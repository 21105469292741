import { Box, Button, Flex, Heading, useDisclosure, useToast, Center, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState, useRef, useEffect, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TrashRemoveIcon } from "@digital-services-gard-as/anchor-chakra";
import { useFeature } from "flagged";

import { useUpdateInvoiceByPutMutation, useDeleteInvoiceMutation, usePostBankAccountMutation, useSubmitInvoiceByPostMutation } from "../../api/invoiceApi";
import { BankAccountDto, InvoiceFormData, InvoiceSummaryDto } from "../../domain/invoice";
import usePrevious from "../../hooks/usePrevious";
import { useSubmit } from "../../hooks/useSubmit";
import { getTotalAmount } from "../../utils/costUtils";
import { extractBankAccounts, convertInvoiceDetailsToForm, StatusBadge } from "../../utils/invoiceUtils";
import { invoiceSchema } from "../../validation/validation";
import { BankDetailsModal, CurrencyChangeModal, DeleteInvoiceModal } from "./modals";
import InvoiceStepper from "./steps/InvoiceStepper";
import SavedStatuses from "../../components/SavedStatuses";
import useAutosave from "../../hooks/invoices/useAutosave";
import useSubmission from "../../hooks/invoices/useSubmission";

type InvoiceFormProps = {
    invoiceSummary: InvoiceSummaryDto
}

const InvoiceForm = ({ invoiceSummary }: InvoiceFormProps) => {
    const { t } = useTranslation(["invoices", "errorMessages"]);
    const navigate = useNavigate();

    const [
        bankAccounts,
        setBankAccounts
    ] = useState<BankAccountDto[]>(() => extractBankAccounts(invoiceSummary.invoiceSender));
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [changedCurrency, setChangedCurrency] = useState<boolean>(false);
    const [missingBankAccount, setMissingBankAccount] = useState<string | undefined>();
    const [hasShownAutosaveError, setHasShownAutosaveError] = useState(false);
    const { isOpen: isDeleteModalOpen, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
    const { isOpen: isBankDetailsOpen, onOpen: onOpenBankDetails, onClose: onCloseBankDetails } = useDisclosure();
    const cancelRef = useRef<HTMLButtonElement | null>(null);
    const lastSavedRef = useRef<number>(Date.now());

    const toast = useToast();
    const [updateInvoice, updateInvoiceResult] = useUpdateInvoiceByPutMutation();
    const [deleteInvoice, deleteInvoiceResult] = useDeleteInvoiceMutation();
    const [addBankAccount, addBankAccountResult] = usePostBankAccountMutation();
    const [submitInvoice, submitInvoiceResult] = useSubmitInvoiceByPostMutation();

    const methods = useForm<InvoiceFormData>({
        resolver: zodResolver(invoiceSchema()),
        mode: "onBlur",
        defaultValues: convertInvoiceDetailsToForm(invoiceSummary.invoiceDetails)
    });
    const prevValues = usePrevious<InvoiceFormData>(methods.getValues());
    const bankAccountWatch = methods.watch("bankAccount");

    const { onSubmit, onErrorSubmit, submitError } = useSubmit({
        bankAccounts,
        totalAmount,
        invoiceSummary,
        submitInvoice,
        formValues: methods.getValues(),
        setError: methods.setError,
        formState: methods.formState
    });

    const isBranchCompanySelected = window.sessionStorage.getItem("selectedBranch");

    const isSubcompanyInvoicesEnabled: boolean = useFeature("CIA-1097-RegisterInvoicesSubcompany") as boolean;

    useAutosave({
        isFormChanged,
        methods,
        bankAccounts,
        updateInvoice,
        setIsFormChanged,
        lastSavedRef,
        invoiceSummary
    });

    useSubmission({
        isSubmitSuccess: submitInvoiceResult.isSuccess,
        toast,
        navigate,
        invoiceId: invoiceSummary.invoiceId,
        isSubmitError: submitInvoiceResult.isError
    });

    useEffect(() => {
        const subscription = methods.watch(() => {
            const fields = methods.getValues();
            setTotalAmount(getTotalAmount(fields));
            setIsFormChanged(true);
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods.watch, bankAccounts]);

    useEffect(() => {
        const prevCurrency = prevValues?.bankAccount?.currency;
        if (prevCurrency && bankAccountWatch?.currency !== prevCurrency) {
            setChangedCurrency(true);
        }

        if (bankAccountWatch && missingBankAccount) {
            setMissingBankAccount(undefined);
        }
    }, [bankAccountWatch, prevValues, missingBankAccount]);

    useEffect(() => {
        if (deleteInvoiceResult.isSuccess) {
            toast({
                id: "invoiceDeleted",
                title: t("invoices:createInvoice.invoiceDeleted"),
                status: "success",
                isClosable: true,
                position: "top"
            });
            navigate("/");
        }

        if (deleteInvoiceResult.isError) {
            toast({
                id: "invoiceDeleteFailed",
                title: t("invoices:createInvoice.invoiceDeleteFailed"),
                status: "error",
                isClosable: true,
                position: "top"
            });
        }
    }, [deleteInvoiceResult, navigate, t, toast]);

    useEffect(() => {
        if (addBankAccountResult.isSuccess) {
            const newBankAccounts = bankAccounts.concat(addBankAccountResult.data);
            setBankAccounts(newBankAccounts);
            toast({
                id: "bankAccountCreated",
                title: t("invoices:createInvoice.bankDetails.newAccountRegistered"),
                status: "success",
                isClosable: true,
                position: "top"
            });
            methods.setValue("bankAccount", addBankAccountResult.data);

            methods.trigger("bankAccount");
        }
        if (addBankAccountResult.isError) {
            toast({
                id: "bankAccountFailed",
                title: t("invoices:createInvoice.bankDetails.newAccountFailed"),
                status: "error",
                isClosable: true,
                position: "top"
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addBankAccountResult, toast]);

    useEffect(() => {
        if (updateInvoiceResult.isError && !hasShownAutosaveError) {
            toast({
                id: "autosaveFailed",
                title: t("errorMessages:autosaveFailedMsg"),
                status: "error",
                isClosable: true,
                position: "top"
            });
            setHasShownAutosaveError(true);
        } else if (updateInvoiceResult.isSuccess && hasShownAutosaveError) {
            setHasShownAutosaveError(false);
        }
    }, [hasShownAutosaveError, t, toast, updateInvoiceResult]);

    const handleCloseCurrencyChangeModal = useCallback(() => {
        setChangedCurrency(false);
    }, []);

    return (
        <Center>
            <Box m={{ base: "1rem", lg: "4rem" }} minW={{ base: "100%", lg: "70rem" }} w="80rem" alignItems="center">
                <DeleteInvoiceModal
                    cancelRef={cancelRef}
                    deleteInvoice={deleteInvoice}
                    invoiceId={invoiceSummary.invoiceId}
                    isDeleteModalOpen={isDeleteModalOpen}
                    onCloseDeleteModal={onCloseDeleteModal}
                />
                <BankDetailsModal
                    invoiceId={invoiceSummary.invoiceId}
                    isOpen={isBankDetailsOpen}
                    onClose={onCloseBankDetails}
                    addBankAccount={addBankAccount}
                />
                <CurrencyChangeModal onClose={handleCloseCurrencyChangeModal} isOpen={changedCurrency} />
                { /* TODO: Unsaved changes will be introduced at a later stage
                <UnsavedChangesModal
                    onClose={() => setShowUnsavedChangesModal(false)}
                    onConfirm={handleUnsavedChangesModal}
                    isOpen={showUnsavedChangesModal}
                />
                */ }
                <Heading size="xl" textAlign="center" id="createInvoiceTitle" mb="2rem">
                    {t("invoices:createInvoice.title")}
                </Heading>
                <Flex
                    gap="1rem"
                    w="100%"
                    border="1px solid"
                    borderColor="anchor.brown.200"
                    borderTopRadius="4px"
                    borderBottom="none"
                    justifyContent="space-between"
                    alignItems="center"
                    px="1rem"
                >
                    <Flex flexDir="column" w="33%" py="0.5rem">
                        { (isSubcompanyInvoicesEnabled && isBranchCompanySelected !== null) && (
                            <>
                                <Text as="b" data-testid="branchCompanyTitle">{t("invoices:branchCompany")}</Text>
                                <Text data-testid="branchCompany">
                                    {JSON.parse(isBranchCompanySelected).companyName}
                                </Text>
                            </>
                        )}
                    </Flex>
                    <Flex justifyContent="center" alignItems="center" w="33%">
                        <Box>
                            <StatusBadge id="statusBadge" size="lg" invoiceStatus={invoiceSummary.status} />
                        </Box>
                    </Flex>
                    <Flex
                        justifyContent={{ base: "center", lg: "flex-end" }}
                        alignItems="center"
                        w="33%"
                        gap="1rem"
                        py="0.5rem"
                    >
                        <SavedStatuses
                            isSaved={updateInvoiceResult.isSuccess}
                            isSaving={updateInvoiceResult.isLoading}
                            isSavingError={updateInvoiceResult.isError}
                            lastSavedRef={lastSavedRef}
                        />
                        <Button
                            id="deleteInvoiceBtn"
                            alignItems="center"
                            leftIcon={<TrashRemoveIcon />}
                            color="#3F6792"
                            variant="secondary"
                            onClick={onOpenDeleteModal}
                        >
                            {t("invoices:createInvoice.delete")}
                        </Button>
                    </Flex>
                </Flex>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit, onErrorSubmit)}>
                        <InvoiceStepper
                            formBankAccount={methods.getValues("bankAccount")}
                            bankAccounts={bankAccounts}
                            invoiceSummary={invoiceSummary}
                            missingBankAccount={missingBankAccount}
                            setMissingBankAccount={setMissingBankAccount}
                            onOpenBankDetails={onOpenBankDetails}
                            submitError={submitError}
                            submitInvoiceLoading={submitInvoiceResult.isLoading}
                            totalAmount={totalAmount}
                        />
                    </form>
                </FormProvider>
            </Box>
        </Center>
    );
};

export default InvoiceForm;
