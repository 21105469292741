import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Grid,
    GridItem,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Stack,
    Tag,
    Text
} from "@chakra-ui/react";
import { HierarchicalDto } from "../../../api/usersApi";
import { ArrowRightIcon } from "@digital-services-gard-as/anchor-chakra";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { TruncatedText } from "../../../components/TableText";

type BranchCompanyModalProps = {
    isOpen: boolean;
    onClose: () => void;
    selectedBranch: HierarchicalDto | null;
    setSelectedBranch: (branch: HierarchicalDto) => void;
    branchCompanies: HierarchicalDto[];
    isLoading: boolean;
    isError: boolean;
};

export const BranchCompanyModal = ({
    isOpen,
    onClose,
    selectedBranch,
    setSelectedBranch,
    branchCompanies,
    isLoading,
    isError
}: BranchCompanyModalProps) => {
    const { t } = useTranslation(["claimsInvoicesOverview", "main"]);
    const handleSelectBranch = useCallback(
        (branch: HierarchicalDto) => {
            setSelectedBranch(branch);
            window.sessionStorage.setItem("selectedBranch", JSON.stringify(branch));
            onClose();
        },
        [onClose, setSelectedBranch]
    );
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay p="1rem" />
            <ModalContent p="1rem" data-testid="branchCompanyModal">
                <ModalHeader textAlign="left">
                    <Text fontSize="lg" fontWeight="bold">
                        {t("claimsInvoicesOverview:branchCompanyModal.title")}
                    </Text>
                </ModalHeader>
                <ModalCloseButton id="branchCompanyModalClose" />
                <ModalBody>
                    {isLoading && (
                        <Stack>
                            <Skeleton height="20px" />
                            <Skeleton height="20px" />
                            <Skeleton height="20px" />
                        </Stack>
                    )}
                    {isError && (
                        <Center>
                            <Text>
                                {t("claimsInvoicesOverview:branchCompanyModal.noData")}
                            </Text>
                        </Center>
                    )}
                    <Flex flexDir="column">
                        {branchCompanies?.map((company, index) => (
                            <Box key={company.crmId}>
                                <Flex alignItems="center" justify="space-between">
                                    <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(2, 1fr)' gap={2}>
                                        <GridItem w="12rem">
                                            <Text as="b">
                                                {t(
                                                    "claimsInvoicesOverview:branchCompanyModal.branchCompany"
                                                )}
                                            </Text>
                                            <Text
                                                whiteSpace="nowrap"
                                                overflow="hidden"
                                                textOverflow="ellipsis"
                                            >
                                                <TruncatedText text={company.companyName} />
                                            </Text>
                                        </GridItem>
                                        <GridItem ml="2rem">
                                            <Text as="b">
                                                {t(
                                                    "claimsInvoicesOverview:branchCompanyModal.branchCompanyCity"
                                                )}</Text>
                                            <Text maxW="12rem">
                                                <TruncatedText text={company.billingCity} />
                                            </Text>
                                        </GridItem>
                                    </Grid>
                                    <Flex>
                                        {selectedBranch?.crmId === company.crmId && (
                                            <Tag
                                                size="sm"
                                                variant="solid"
                                                colorScheme="blue"
                                            >
                                                {t(
                                                    "claimsInvoicesOverview:branchCompanyModal.selected"
                                                )}
                                            </Tag>
                                        )}
                                    </Flex>
                                    <Flex>
                                        <IconButton
                                            data-testid="selectBtn"
                                            size="sm"
                                            variant="primary"
                                            icon={<ArrowRightIcon size="sm" />}
                                            aria-label="Select company"
                                            onClick={() => handleSelectBranch(company)}
                                        />
                                    </Flex>
                                </Flex>
                                {branchCompanies.length - 1 > index && <Divider />}
                            </Box>
                        ))}
                    </Flex>
                    <Flex flexDir="column" gap="1rem" mt="1rem">
                        <Button data-testid="cancelBtn" w="100%" variant="ghost" onClick={onClose}>
                            {t("main:cancel")}
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
