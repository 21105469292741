import { Tr, Td } from "@chakra-ui/react";
import { Cell, Row, flexRender } from "@tanstack/react-table";
import { Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { InvoiceDto } from "../../../api/invoiceApi";

type InvoicesTableRowProps = {
    row: Row<InvoiceDto>,
    submittedInvoiceId: string | undefined
}

const InvoicesTableRow = ({ row, submittedInvoiceId }: InvoicesTableRowProps) => {
    const navigate = useNavigate();

    const handleKeyUp = useCallback((e: React.KeyboardEvent<HTMLTableRowElement>) => {
        if (e.key === "Enter") {
            navigate("/invoice/" + row.original.id);
        }
    }, [navigate, row.original.id]);

    const handleRowClick = useCallback(() => {
        navigate("/invoice/" + row.original.id);
    }, [navigate, row.original.id]);

    const handleBackgroundColor = (cell: Cell<InvoiceDto, unknown>) =>
        (cell.getContext().column.getIsSorted() || row.getIsExpanded()) ? "anchor.brown.100" : {};

    return (
        <Fragment key={row.id}>
            <Tr
                onClick={handleRowClick}
                backgroundColor={submittedInvoiceId === row.original.id ? "#F5F7F8" : "#FFFFF"}
                _hover={{
                    cursor: "pointer",
                    backgroundColor: "#FAFBFB"
                }}
                tabIndex={0}
                onKeyUp={handleKeyUp}
            >
                {row.getVisibleCells().map(cell => {
                    return (
                        <Td key={cell.id}
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            backgroundColor={handleBackgroundColor(cell)}
                            fontSize={"16px"}
                            wordBreak="break-word"
                            py="1rem"
                        >
                            {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                            )}
                        </Td>
                    );
                })}
            </Tr>
        </Fragment>
    );
};

export default InvoicesTableRow;
