import { Button, Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MutableRefObject, useCallback, useEffect, useState } from "react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { isFetchBaseQueryError, isSerializeError } from "../../../utils/errorUtils";
import { createInvoiceAddressText } from "../../../utils/addressUtils";
import { useGetClaimsMemberQuery } from "../../../api/claimsApi";

type InvoiceAddressModalProps = {
    isOpen: boolean,
    onClose: () => void,
    claimIdRef: MutableRefObject<string>
}

const NOT_FOUND_STATUS = 404;

export const InvoiceAddressModal = ({ isOpen, onClose, claimIdRef }: InvoiceAddressModalProps) => {
    const { t } = useTranslation(["errorMessages", "claimsInvoicesOverview"]);
    const [address, setAddress] = useState<string>("");
    const toast = useToast({
        id: "invoiceAddressCopied",
        title: t("claimsInvoicesOverview:invoiceAddressModal.copyToast"),
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top"
    });

    const {
        currentData: data,
        isFetching,
        isError,
        error
    } = useGetClaimsMemberQuery(claimIdRef.current, { skip: !isOpen });

    useEffect(() => {
        if (data || isNotFoundError(error)) {
            const addr = createInvoiceAddressText(data);
            setAddress(addr);
        }
    }, [data, error]);

    const handleCopyInvoiceClick = useCallback(() => {
        navigator.clipboard.writeText(address);
        onClose();
        toast();
    }, [address, onClose, toast]);

    const showAddress = () => {
        if (isFetching) {
            return (
                <Center>
                    <Flex flexDir="column" alignItems="center">
                        <Spinner />
                        <Text>{t("claimsInvoicesOverview:invoiceAddressModal.retrievingAddress")}</Text>
                    </Flex>
                </Center>
            );
        }

        if (isError && (isSerializeError(error) || !isNotFoundError(error))) {
            return <Text>{t("errorMessages:invoiceAddressModal.noAddress")}</Text>;
        }

        return (
            <Flex flexDir="column" gap="1rem">
                <Text>{t("claimsInvoicesOverview:invoiceAddressModal.description")}</Text>
                <Flex flexDir="column">
                    <Text whiteSpace="pre-wrap" data-testid="invoiceAddress">
                        {address}
                    </Text>
                </Flex>
                <Flex gap="1rem" mb="1rem" alignItems="start">
                    <Button id="copyInvoiceAddressBtn" onClick={handleCopyInvoiceClick}>
                        {t("claimsInvoicesOverview:invoiceAddressModal.copyBtn")}
                    </Button>
                    <Button id="cancelBtn" variant="ghost" onClick={onClose}>
                        {t("claimsInvoicesOverview:invoiceAddressModal.cancelBtn")}
                    </Button>
                </Flex>
            </Flex>
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} id="invoiceAddressModal">
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>
                    {t("claimsInvoicesOverview:invoiceAddressModal.title")}
                </ModalHeader>
                <ModalBody>
                    {showAddress()}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const isNotFoundError = (error: SerializedError | FetchBaseQueryError | undefined) => {
    if (!error) {
        return false;
    }

    if (isSerializeError(error)) {
        return false;
    }

    if (isFetchBaseQueryError(error)) {
        return error.status === NOT_FOUND_STATUS;
    }

    return false;
};
