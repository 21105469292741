import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Box
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

type RejectedInvoiceModalProps = {
    isOpen: boolean;
    onClose: () => void;
    rejectedInvoiceMessage: string;
};

type RejectedClaimsHandlerProps = {
    message: string;
};

const RejectedClaimsHandler = ({ message }: RejectedClaimsHandlerProps) => {
    const { t } = useTranslation("invoices");
    return(
        <Flex flexDir={"column"}>
            <Flex>
                <Text fontSize="13px" as="b">
                    {t("rejectedInvoice.claimsHandlerMessage")}
                </Text>
            </Flex>
            <Box bgColor={"anchor.brown.200"} borderRadius="5px" p="1rem">
                <Text as="em">
                    {message}
                </Text>
            </Box>
        </Flex>
    );
};

const RejectedInvoiceDefault = () => {
    const { t } = useTranslation("invoices");
    return(
        <Flex>
            <Text>
                {t("rejectedInvoice.defaultMessage")}
            </Text>
        </Flex>
    );
};

const DisplayRejectedInvoice = ({ message }: RejectedClaimsHandlerProps) => {
    if(message === "") {
        return <RejectedInvoiceDefault />;
    } else{
        return <RejectedClaimsHandler message={message} />;
    }
};


export const RejectedInvoiceModal = ({
    isOpen,
    onClose,
    rejectedInvoiceMessage
}: RejectedInvoiceModalProps) => {
    const { t } = useTranslation(["invoices", "main"]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} >
            <ModalOverlay p="1rem" />
            <ModalContent p="1rem" data-testid="rejectedInvoiceModal">
                <ModalHeader textAlign="left">
                    <Text fontSize="lg" fontWeight="bold">
                        {t("invoices:rejectedInvoice.title")}
                    </Text>
                </ModalHeader>
                <ModalCloseButton id="rejectedInvoiceModalClose" />
                <ModalBody>
                    <Flex gap={5} flexDir="column">
                        <Flex>
                            <DisplayRejectedInvoice message={rejectedInvoiceMessage} />
                        </Flex>
                        <Flex>
                            {t("invoices:rejectedInvoice.claimsHandler")}
                        </Flex>
                        {/* <Flex mb="1rem">
                            <Link href={"mailto:" + claimsContact} >
                                <Text as="u">
                                    {claimsContact}
                                </Text>
                            </Link>
                        </Flex> */}
                    </Flex>
                    <Flex gap="1rem" mt="1rem" w="fit-content" h="fit-content">
                            <Button data-testid="cancelBtn" variant="secondary"
                            borderRadius="5px" onClick={onClose}>
                                {t("main:close")}
                            </Button>
                        </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
