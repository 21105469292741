import React, { useCallback } from "react";
import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Select, SimpleGrid, Text } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BankAccountDto, ClaimDetailsDto, CompanyDto, InvoiceDetailsDto, InvoiceFormData, MemberDetailsDto } from "../../../domain/invoice";
import { createInvoiceAddressText } from "../../../utils/addressUtils";

type GeneralInfoStepProps = {
    claimDetails?: ClaimDetailsDto,
    invoiceSender?: CompanyDto,
    invoiceDetails?: InvoiceDetailsDto,
    memberDetails?: MemberDetailsDto,
    bankAccounts: BankAccountDto[],
    onOpenBankDetails: () => void,
    missingBankAccount: string | undefined,
    handleNextStepClick: (e: React.MouseEvent) => void;
}

export const GeneralInfoStep = React.memo(function GeneralInfoStep({
    claimDetails,
    invoiceSender,
    invoiceDetails,
    memberDetails,
    bankAccounts,
    onOpenBankDetails,
    missingBankAccount,
    handleNextStepClick
}: GeneralInfoStepProps) {
    const { t } = useTranslation(["invoices", "errorMessages"]);
    const { watch, setValue } = useFormContext<InvoiceFormData>();

    const bankAccountWatcher = watch("bankAccount");

    const handleBankAccountChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const splitString = e.target.value.split(" - ");
        const account = {
            accountNumber: splitString[0],
            currency: splitString[1]
        };
        const selectedBankAccount = bankAccounts.find(value => (value.accountNumber === account.accountNumber)
            && (value.currency === account.currency));
        if (selectedBankAccount) {
            setValue("bankAccount", selectedBankAccount);
        }
    }, [bankAccounts, setValue]);

    const getSelectedBankAccount = (bankAccount: BankAccountDto) =>
        bankAccount.accountNumber === bankAccountWatcher?.accountNumber
            && bankAccount.currency === bankAccountWatcher?.currency;

    return (
        <Box margin={{ base: "1rem 2rem", lg: "4rem 8rem" }}>
            <Heading size="lg" id="generalInfoTitle">{t("createInvoice.generalInfoTitle")}</Heading>
            { /* eslint-disable-next-line no-magic-numbers */ }
            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10} justifyContent="center" mt="1rem">
                <Flex flexDir={"column"}>
                    <FormLabel>{t("invoices:createInvoice.generalInfo.subClaimNo")}</FormLabel>
                    <Text id="subClaimNo">{invoiceDetails?.subClaimsRef}</Text>
                    <Flex mt="2rem" gap={10}>
                        <FormControl width="70%" isInvalid={!!missingBankAccount}>
                            <FormLabel>{t("invoices:createInvoice.generalInfo.bankAccount")}</FormLabel>
                            <Select
                                id="bankAccount"
                                placeholder={
                                    t("invoices:createInvoice.generalInfo.bankAccountPlaceholder") as string | undefined
                                }
                                onChange={handleBankAccountChange}
                            >
                                {bankAccounts?.map((value) => (
                                    <option
                                        key={value.accountNumber + value.currency}
                                        selected={getSelectedBankAccount(value)}
                                    >{value.accountNumber} - {value.currency}</option>
                                ))}
                            </Select>
                            <FormErrorMessage data-testid="missingBankAccountError">
                                {missingBankAccount}
                            </FormErrorMessage>
                        </FormControl>
                        <Flex flexDir={"column"} id="currency">
                            <FormLabel>{t("invoices:createInvoice.generalInfo.currency")}</FormLabel>
                            <Text>{bankAccountWatcher?.currency}</Text>
                        </Flex>
                    </Flex>
                    <Button
                        id="addBankAccountBtn"
                        variant="outline"
                        mt="2rem"
                        width="70%"
                        leftIcon={<AddIcon />}
                        onClick={onOpenBankDetails}
                        minW="13rem"
                    >
                        {t("invoices:createInvoice.generalInfo.addBankAccountBtn")}
                    </Button>
                    <Flex alignItems="flex-end" h="full" pb="2rem">
                        <Button id="nextBtn" type="button" onClick={handleNextStepClick}>
                            {t("invoices:createInvoice.nextBtn")}
                        </Button>
                    </Flex>
                </Flex>
                <Flex
                    padding="1rem"
                    flexDir="column"
                    gap={4}
                    bg="anchor.gray.50"
                >
                    <Flex flexDir={"column"}>
                        <Heading
                            as="h4"
                            size={"md"}
                            mb="0.5rem"
                            id="invoiceTo"
                        >{t("invoices:createInvoice.generalInfo.invoiceTo")}</Heading>
                        <Text whiteSpace="pre-wrap" data-testid="invoiceAddress">
                            {createInvoiceAddressText(memberDetails)}
                        </Text>
                    </Flex>
                    <Flex flexDir={"column"}>
                        <Text id="gardClaimRef" as="b" mb="0.5rem">{
                            t("invoices:createInvoice.generalInfo.gardClaimRef")}
                        </Text>
                        <Text id="gardClaimRefValue">{claimDetails?.id}</Text>
                    </Flex>
                    <Flex flexDir={"column"}>
                        <Text id="invoiceFrom" as="b" mb="0.5rem">
                            {t("invoices:createInvoice.generalInfo.invoiceFrom")}
                        </Text>
                        <Text id="invoiceFromValue">{invoiceSender?.name}</Text>
                    </Flex>
                    <Flex flexDir={"column"}>
                        <Text id="clientName" as="b" mb="0.5rem">{
                            t("invoices:createInvoice.generalInfo.clientName")}
                        </Text>
                        <Text id="clientNameValue">{claimDetails?.clientName}</Text>
                    </Flex>
                    <Flex flexDir={"column"}>
                        <Text id="vessel" as="b" mb="0.5rem">
                            {t("invoices:createInvoice.generalInfo.vesselName")}
                        </Text>
                        <Text id="vesselValue">{claimDetails?.vessel}</Text>
                    </Flex>
                </Flex>
            </SimpleGrid>
        </Box>
    );
});
