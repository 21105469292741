import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Center, Flex, Skeleton, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
    useGetInvoiceSummaryQuery
} from "../api/invoiceApi";
import { InvoiceStatus, InvoiceSummaryDto } from "../domain/invoice";
import InvoiceForm from "./createInvoice/InvoiceForm";
import InvoiceReceipt from "./invoiceReceipt/InvoiceReceipt";

type InvoiceState = {
    state: {
        invoiceSummary: InvoiceSummaryDto
    } | null
}

const Invoice = () => {
    const { invoiceId } = useParams();
    const { state } = useLocation() as InvoiceState;
    const locInvoiceSummary = state ? state.invoiceSummary : undefined;
    const [invoiceSummary, setInvoiceSummary] = useState<InvoiceSummaryDto | undefined>(locInvoiceSummary);
    const { t } = useTranslation("invoices");

    if (invoiceId === undefined) {
        throw new Error("Invoice id is undefined");
    }

    const { isAuthenticated, signinRedirect, isLoading } = useAuth();
    const {
        currentData: apiInvoiceSummary,
        isFetching: isFetchingInvoiceSummary
    } = useGetInvoiceSummaryQuery(invoiceId, {
        skip: invoiceSummary !== undefined
    });

    useEffect(() => {
        if (apiInvoiceSummary) {
            setInvoiceSummary(apiInvoiceSummary);
        }
    }, [apiInvoiceSummary]);

    if (!isLoading && !isAuthenticated) {
        signinRedirect({ state: window.location.href });
    }

    if (isFetchingInvoiceSummary) {
        return (
            <Center>
                <Flex flexDir="column" alignItems="center" mt="4rem">
                    <Spinner />
                    <Text>{t("fetchInvoiceSummarySpinner")}</Text>
                </Flex>
            </Center>
        );
    }

    if (!invoiceSummary) {
        return <div />;
    }

    return (
        <Skeleton isLoaded={!!invoiceSummary}>
            <Box>
                <Breadcrumb ml={{ base: "10%", lg: "20%" }} mt="1rem">
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/" tabIndex={0} color="anchor.navy.600">Home</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Text>Invoice</Text>
                    </BreadcrumbItem>
                </Breadcrumb>
                { InvoiceStatus[invoiceSummary.status as keyof typeof InvoiceStatus] === InvoiceStatus.Draft ? (
                    <InvoiceForm invoiceSummary={invoiceSummary} />
                ) : (
                    <InvoiceReceipt invoiceSummary={invoiceSummary} />
                )}
            </Box>
        </Skeleton>
    );
};

export default Invoice;
