import React, { useCallback, useEffect } from "react";
import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Spinner,
    Text
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
    getAttachment,
    useUploadAttachmentByPostMutation
} from "../../../api/invoiceApi";
import FileUpload from "../../../components/FileUpload";
import { FormNumberInput, AdvanceOfFunds } from "./formInput";
import { InvoiceFormData, InvoiceAttachment, MemberDetailsDto } from "../../../domain/invoice";
import { formatCostText } from "../../../utils/invoiceUtils";
import { InvoiceConfirmation } from "./formInput/InvoiceConfirmation";

type FeesExpensesStepProps = {
    totalAmount: number;
    submitError?: string;
    invoiceId: string;
    invoiceRecipient: MemberDetailsDto | undefined;
    goToPrevious: () => void;
    submitInvoiceLoading: boolean;
};

export const FeesExpensesStep = React.memo(function FeesExpensesStep({
    totalAmount,
    submitError,
    invoiceId,
    invoiceRecipient,
    goToPrevious,
    submitInvoiceLoading
}: FeesExpensesStepProps) {
    const { t } = useTranslation(["main", "invoices", "errorMessages"]);
    const {
        register,
        control,
        getValues,
        setValue,
        watch,
        formState: { errors, dirtyFields }
    } = useFormContext<InvoiceFormData>();
    const watchInvoiceAttachment = watch("invoiceAttachment");
    const watchBankAccount = watch("bankAccount");
    const watchHasAdvanceOfFunds = watch("hasAdvanceOfFunds");
    const watchInvoiceDate = watch("invoiceDate");


    const [
        uploadAttachment,
        {
            data: attachmentResponse,
            isSuccess: attachmentUploaded,
            isError: uploadFailed,
            isLoading: isUploadingAttachment
        }
    ] = useUploadAttachmentByPostMutation();

    useEffect(() => {
        const defaultDueDateDays = 31;

        const todaysDate = new Date();

        const invoiceDueDateDefault = new Date(todaysDate.getFullYear(),
        todaysDate.getMonth(), todaysDate.getDate() + defaultDueDateDays);

        if(getValues().invoiceDate !== "" && !dirtyFields.invoiceDueDate) {
            setValue("invoiceDueDate", invoiceDueDateDefault.toISOString().split("T")[0]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchInvoiceDate]);

    useEffect(() => {
        const attachment = getValues().invoiceAttachment;
        const form = new FormData();
        if (attachment?.file && !attachment?.isUploading && !attachment?.isUploaded && !attachment?.uploadFailed) {
            form.append("formFile", attachment.file);
            form.append("filename", attachment.file.name);

            uploadAttachment({
                id: invoiceId,
                form
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchInvoiceAttachment]);

    useEffect(() => {
        if (isUploadingAttachment) {
            const attachment = getValues().invoiceAttachment;

            if (!attachment) {
                return;
            }

            const attachmentLoading: InvoiceAttachment = {
                ...attachment,
                isUploading: true,
                isUploaded: false,
                uploadFailed: false
            };

            setValue("invoiceAttachment", attachmentLoading);
        }
    }, [isUploadingAttachment, getValues, setValue]);

    useEffect(() => {
        if (attachmentUploaded && attachmentResponse) {
            const attachment = getValues().invoiceAttachment;

            if (!attachment) {
                return;
            }

            const uploadedAttachment = {
                ...attachment,
                isUploaded: true,
                isUploading: false,
                uploadFailed: false,
                docId: attachmentResponse
            };

            setValue("invoiceAttachment", uploadedAttachment);
        }
    }, [attachmentUploaded, attachmentResponse, getValues, setValue]);

    useEffect(() => {
        if (uploadFailed) {
            const attachment = getValues().invoiceAttachment;

            if (!attachment) {
                return;
            }

            const failedAttachment = {
                ...attachment,
                isUploaded: false,
                isUploading: false,
                uploadFailed: true
            };

            setValue("invoiceAttachment", failedAttachment);
        }
    }, [uploadFailed, getValues, setValue]);

    const handleDeleteClick = useCallback(() => {
        const file = getValues().invoiceAttachment;

        if (!file) {
            return;
        }

        setValue("invoiceAttachment", undefined);
    }, [getValues, setValue]);

    const handleAttachmentClick = useCallback((invoiceIdentifier: string, docId: string | undefined) => {
        if (docId === undefined) {
            return;
        }

        getAttachment(invoiceIdentifier, docId);
    }, []);

    const handleRadioChange = useCallback((e: any) => {
        const value = e.target.value === "true";
        setValue("hasAdvanceOfFunds", value);
    }, [setValue]);

    return (
        <Box margin={{ base: "1rem 2rem", lg: "4rem 8rem" }}>
            <Flex>
                <Flex gap={"2.5rem"} flexDir="column" w="100%">
                    <Heading size="lg">
                        {t("invoices:createInvoice.feesAndExpensesTitle")}
                    </Heading>
                    <FormControl maxW="10rem" isInvalid={!!errors.invoiceDate?.message}>
                        <FormLabel>
                            {t("invoices:createInvoice.feesAndExpenses.invoiceDate")}
                        </FormLabel>
                        <Input
                            id="invoiceDate"
                            {...register("invoiceDate")}
                            type="date"
                            autoComplete="off"
                            data-lpignore="true"
                        />
                        <FormErrorMessage data-testid="invoiceDateError">
                            {errors.invoiceDate?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl maxW="10rem" isInvalid={!!errors.invoiceDueDate?.message}>
                        <FormLabel>
                            {t("invoices:createInvoice.feesAndExpenses.invoiceDueDate")}
                        </FormLabel>
                        <Input
                            id="invoiceDueDate"
                            {...register("invoiceDueDate")}
                            type="date"
                            autoComplete="off"
                            data-lpignore="true"
                        />
                        <FormErrorMessage data-testid="invoiceDueDateError">
                            {errors.invoiceDueDate?.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl
                        isInvalid={!!errors.invoiceReference?.message}
                        maxW="20rem"
                    >
                        <FormLabel>
                            {t("invoices:createInvoice.feesAndExpenses.invoiceReference")}
                        </FormLabel>
                        <Input id="invoiceReference" {...register("invoiceReference")} data-lpignore="true" />
                        <FormErrorMessage data-testid="invoiceReferenceError">
                            {errors.invoiceReference?.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.invoiceAttachment?.message} w="100%">
                        <FormLabel>
                            {t("invoices:createInvoice.feesAndExpenses.attachInvoice")}
                        </FormLabel>
                        <FileUpload
                            id="invoiceAttachment"
                            name={"invoiceAttachment"}
                            invoiceId={invoiceId}
                            onDeleteClick={handleDeleteClick}
                            onAttachmentClick={handleAttachmentClick}
                            accept={{
                                "image/jpeg": [
                                    ".png",
                                    ".jpg",
                                    ".jpeg",
                                    ".tiff",
                                    ".tif",
                                    ".gif"
                                ],
                                "application/pdf": [".pdf"]
                            }}
                            isInvalid={!!errors.invoiceAttachment?.message}
                        />
                        <FormErrorMessage data-testid="invoiceAttachmentError">
                            {errors.invoiceAttachment?.message}
                        </FormErrorMessage>
                    </FormControl>
                    <Divider />
                    <Text maxW="40rem">
                        {t("invoices:createInvoice.feesAndExpenses.provideValues")}
                    </Text>
                    {submitError === t("errorMessages:invoiceForm.missingCost") && (
                        <Text color="anchor.red.300" data-testid="missingCostError">{submitError}</Text>
                    )}
                    <FormNumberInput
                        id="correspondentFees"
                        label={t(
                            "invoices:createInvoice.feesAndExpenses.correspondentFees"
                        )}
                        isRequired={false}
                        errorMessage={errors.correspondentFees?.message}
                        control={control}
                        currency={watchBankAccount?.currency}
                    />
                    <FormNumberInput
                        id="surveyorFees"
                        label={t("invoices:createInvoice.feesAndExpenses.surveyorFees")}
                        isRequired={false}
                        errorMessage={errors.surveyorFees?.message}
                        control={control}
                        currency={watchBankAccount?.currency}
                    />
                    <FormNumberInput
                        id="expertFees"
                        label={t("invoices:createInvoice.feesAndExpenses.expertFees")}
                        isRequired={false}
                        errorMessage={errors.expertFees?.message}
                        control={control}
                        currency={watchBankAccount?.currency}
                    />
                    <FormNumberInput
                        id="lawyerFees"
                        label={t("invoices:createInvoice.feesAndExpenses.lawyerFees")}
                        isRequired={false}
                        errorMessage={errors.lawyerFees?.message}
                        control={control}
                        currency={watchBankAccount?.currency}
                    />
                    <FormNumberInput
                        id="lawyerExpenses"
                        label={t("invoices:createInvoice.feesAndExpenses.lawyerExpenses")}
                        isRequired={false}
                        errorMessage={errors.lawyerExpenses?.message}
                        control={control}
                        currency={watchBankAccount?.currency}
                    />
                    <FormNumberInput
                        id="otherExpenses"
                        label={t("invoices:createInvoice.feesAndExpenses.otherExpenses")}
                        isRequired={false}
                        errorMessage={errors.otherExpenses?.message}
                        control={control}
                        currency={watchBankAccount?.currency}
                    />
                    <FormNumberInput
                        id="totalAmountVAT"
                        label={t("invoices:createInvoice.feesAndExpenses.totalAmountVAT")}
                        isRequired={false}
                        errorMessage={errors.totalAmountVAT?.message}
                        control={control}
                        currency={watchBankAccount?.currency}
                        isDisabled={invoiceRecipient?.vatNumber === "" || invoiceRecipient?.vatNumber === undefined}
                    />
                    <FormNumberInput
                        id="totalAmountLocalTaxes"
                        label={t(
                            "invoices:createInvoice.feesAndExpenses.totalAmountLocalTaxes"
                        )}
                        isRequired={false}
                        errorMessage={errors.totalAmountLocalTaxes?.message}
                        control={control}
                        currency={watchBankAccount?.currency}
                    />
                    <Divider />
                    <AdvanceOfFunds
                        errors={errors}
                        control={control}
                        currency={watchBankAccount?.currency}
                        hasAdvanceOfFunds={watchHasAdvanceOfFunds}
                        handleRadioChange={handleRadioChange}
                    />
                    <Divider />
                    <InvoiceConfirmation invoiceRecipient={invoiceRecipient} register={register} errors={errors} />
                    <Divider />
                    <Flex flexDir={"column"} gap={"1rem"}>
                        <Text as="b">
                            {t(
                                "invoices:createInvoice.feesAndExpenses.totalFeesExpensesTaxes"
                            )}
                        </Text>
                        <Text fontSize="2xl" as="b" data-testid="totalAmount">
                            {formatCostText(totalAmount, watchBankAccount?.currency)}
                        </Text>
                    </Flex>
                    <Flex>
                        <Button
                            id="previousBtn"
                            mr={4}
                            variant="outline"
                            onClick={goToPrevious}
                            type="button"
                        >
                            {t("invoices:createInvoice.previousBtn")}
                        </Button>
                        <Box>
                            <Button id="finishBtn" variant="primary" type="submit">
                                {submitInvoiceLoading ? (
                                    <Spinner />
                                ) : (
                                    t("invoices:createInvoice.finishBtn")
                                )}
                            </Button>
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
});
