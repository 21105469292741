import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import main_en from "./translations/en/main.json";
import invoices_en from "./translations/en/invoices.json";
import errorMessages_en from "./translations/en/errorMessages.json";
import admin_en from "./translations/en/admin.json";
import claimsInvoicesOverview_en from "./translations/en/claimsInvoicesOverview.json";
import externalLanding_en from "./translations/en/externalLading.json";

export const resources = {
    en: {
        admin: admin_en,
        main: main_en,
        invoices: invoices_en,
        errorMessages: errorMessages_en,
        claimsInvoicesOverview: claimsInvoicesOverview_en,
        externalLanding: externalLanding_en
    }
    /*no: {
        admin: admin_no,
        main: main_no,
        invoices: invoices_no,
        errorMessages: errorMessages_no
    }*/
}

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    ns: ["main", "invoices", "errorMessages", "admin", "claimsInvoicesOverview", "externalLanding"],
    defaultNS: "main",
    load: "languageOnly",
    returnNull: false,
    returnEmptyString: false
});

export default i18n;