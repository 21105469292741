import { useCallback } from "react";
import { Box, Flex, Step as ChakraStep, StepIcon, StepIndicator, StepNumber, Stepper, StepStatus, StepTitle, Text, useSteps, Show, StepSeparator, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { BankAccountDto, InvoiceSummaryDto } from "../../../domain/invoice";
import { FeesExpensesStep } from "./FeesExpensesStep";
import { GeneralInfoStep } from "./GeneralInfoStep";
import { useNavigate } from "react-router-dom";

type InvoiceStepperProps = {
    formBankAccount: BankAccountDto | undefined;
    invoiceSummary: InvoiceSummaryDto;
    submitError: string;
    submitInvoiceLoading: boolean;
    bankAccounts: BankAccountDto[];
    totalAmount: number;
    missingBankAccount: string | undefined;
    setMissingBankAccount: (errorMessage: string) => void;
    onOpenBankDetails: () => void;
};

const InvoiceStepper = ({
    formBankAccount,
    invoiceSummary,
    submitError,
    submitInvoiceLoading,
    bankAccounts,
    totalAmount,
    missingBankAccount,
    setMissingBankAccount,
    onOpenBankDetails
}: InvoiceStepperProps) => {
    const { t } = useTranslation(["invoices", "errorMessages"]);
    const navigate = useNavigate();
    const steps = [
        {
            title: t("createInvoice.generalInfoTitle")
        },
        {
            title: t("createInvoice.feesAndExpensesTitle")
        }
    ];

    const { goToNext, goToPrevious, setActiveStep, activeStep } = useSteps({
        index: 0,
        count: steps.length
    });

    const handleNextStepClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        const result = formBankAccount;

        if (!result) {
            setMissingBankAccount(t("errorMessages:invoiceForm.missingBankAccount") as string);
            return;
        }

        goToNext();
    }, [formBankAccount, goToNext, setMissingBankAccount, t]);

    const stepperContent = () => {
        if (activeStep === 0) {
            return (
                <GeneralInfoStep
                    claimDetails={invoiceSummary.claimDetails}
                    invoiceSender={invoiceSummary.invoiceSender}
                    invoiceDetails={invoiceSummary.invoiceDetails}
                    memberDetails={invoiceSummary.invoiceRecipient}
                    bankAccounts={bankAccounts}
                    onOpenBankDetails={onOpenBankDetails}
                    missingBankAccount={missingBankAccount}
                    handleNextStepClick={handleNextStepClick}
                />
            );
        }
        if (activeStep === 1) {
            return (
                <FeesExpensesStep
                    totalAmount={totalAmount}
                    submitError={submitError}
                    invoiceId={invoiceSummary.invoiceId}
                    invoiceRecipient={invoiceSummary.invoiceRecipient}
                    submitInvoiceLoading={submitInvoiceLoading}
                    goToPrevious={goToPrevious}
                />
            );
        }
    };

    const Step = ({ title, index }: { title: string, index: number }) => {
        const handleStepClick = useCallback(async() => {
            const result = formBankAccount;

            if (!result) {
                setMissingBankAccount(t("errorMessages:invoiceForm.missingBankAccount") as string);
                return;
            }

            setActiveStep(index);
        }, [index]);

        return (
            <ChakraStep
                id={"step" + index}
                key={title}
                onClick={handleStepClick}
                onKeyUp={handleStepClick}
                tabIndex={0}
            >
                <StepIndicator _hover={{ cursor: "pointer" }}>
                    <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                    />
                </StepIndicator>
                <Box flexShrink="0" _hover={{ cursor: "pointer" }}>
                    <StepTitle>{title}</StepTitle>
                </Box>
                <StepSeparator />
            </ChakraStep>
        );
    };

    return (
        <Flex flexDir="column" justifyContent="center">
            <Box
                mb="2rem"
                backgroundColor="white"
                border="1px solid"
                borderColor="anchor.brown.200"
                borderBottomRadius="4px"
                boxShadow="md"
                width="100%"
            >
                <Flex w="100%" gap={2}>
                    <Show above="lg">
                        <Box w={"50rem"}>
                            <Stepper
                                index={activeStep}
                                textAlign="center"
                                mx="auto"
                                px="8rem"
                                pt="4rem"
                                size="lg"
                                colorScheme="green"
                                orientation="horizontal"
                            >
                                {steps.map(({ title }, index) => (
                                    <Step key={title} title={title} index={index} />
                                ))}
                            </Stepper>
                        </Box>
                    </Show>
                    <Show below="lg">
                        <Stepper
                            index={activeStep}
                            textAlign="center"
                            mx="auto"
                            padding="0 2rem"
                            size="lg"
                            colorScheme="green"
                            orientation="vertical"
                        >
                            {steps.map(({ title }, index) => (
                                <Step key={title} title={title} index={index} />
                            ))}
                        </Stepper>
                    </Show>
                </Flex>
                {stepperContent()}
            </Box>
            {submitError && (
                <Flex justifyContent="center" mt="1rem">
                    <Text data-testid="submitError" color="anchor.red.50">{submitError}</Text>
                </Flex>
            )}
            <Flex justifyContent="center" mt="1rem" color="anchor.navy.900" fontWeight="700">
                <Button variant="tertiary" onClick={() => navigate("/")}>{t("invoices:backToOverview")}</Button>
            </Flex>
        </Flex>
    );
};

export default InvoiceStepper;
