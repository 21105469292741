import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Container,
    Flex,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { PaginationState, SortingState } from "@tanstack/react-table";

import {
    DEFAULT_QUERY_FILTER,
    useLazyGetClaimsByCompanyIdQuery,
    useLazyGetClaimsInfoByIdQuery
} from "../../api/claimsApi";
import { useCreateInvoiceByPostMutation } from "../../api/invoiceApi";
import ClaimsTable from "./table/ClaimsTable";
import { InvoiceAddressModal } from "./modals/InvoiceAddressModal";
import SubclaimsModal from "./modals/SubclaimsModal";
import ClaimsSearch from "./ClaimsSearch";

export const ClaimsOverview = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(["claimsInvoicesOverview", "errorMessages"]);
    const createInvoiceFailed = useToast({
        id: "createInvoiceFailed",
        title: t("errorMessages:createInvoiceFailed"),
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
    });
    const [{ pageSize, pageIndex }, setPagination] = useState<PaginationState>({
        pageSize: DEFAULT_QUERY_FILTER.limit,
        pageIndex: DEFAULT_QUERY_FILTER.page
    });
    const [sorting, setSorting] = useState<SortingState>([{ id: "incidentDate", desc: true }]);

    const claimIdRef = useRef<string>("");

    const {
        isOpen: isSubclaimsModalOpen,
        onOpen: onOpenSubclaimsModal,
        onClose: onCloseSubclaimsModal
    } = useDisclosure();
    const {
        isOpen: isInvoiceAddressModalOpen,
        onOpen: onOpenInvoiceAddressModal,
        onClose: onCloseInvoiceAddressModal
    } = useDisclosure();

    const [
        getClaimsByCompanyId,
        {
            originalArgs: claimsByCompanyIdArgs,
            currentData: claimsSearchResult,
            isFetching: isFetchingClaims,
            isError: isClaimsSearchError
        }
    ] = useLazyGetClaimsByCompanyIdQuery();
    const [
        createInvoice,
        {
            data: createdInvoice,
            isLoading: isCreatingInvoice,
            isSuccess: isInvoiceCreated,
            originalArgs: invoiceArgs,
            isError: isCreateInvoiceError
        }
    ] = useCreateInvoiceByPostMutation();
    const [
        getClaimsInfo,
        {
            originalArgs: claimsInfoArgs,
            currentData: claimsInfo,
            isFetching: isLoadingClaimsInfo
        }
    ] = useLazyGetClaimsInfoByIdQuery();

    useEffect(() => {
        getClaimsByCompanyId({ id: claimsByCompanyIdArgs?.id, params: DEFAULT_QUERY_FILTER });
    }, [claimsByCompanyIdArgs?.id, getClaimsByCompanyId]);

    useEffect(() => {
        if (!claimsByCompanyIdArgs) {
            getClaimsByCompanyId({
                params: {
                    ...DEFAULT_QUERY_FILTER,
                    limit: pageSize,
                    page: pageIndex
                }
            });
            return;
        }

        getClaimsByCompanyId({
            id: claimsByCompanyIdArgs.id,
            params: {
                ...claimsByCompanyIdArgs.params,
                limit: pageSize,
                page: pageIndex
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getClaimsByCompanyId, pageIndex, pageSize]);

    useEffect(() => {
        if (!claimsByCompanyIdArgs && sorting.length > 0) {
            getClaimsByCompanyId({ params: {
                ...DEFAULT_QUERY_FILTER,
                sortBy: sorting[0].id,
                sortDirection: sorting[0].desc ? "DESC" : "ASC"
            } });
        }

        if (claimsByCompanyIdArgs && sorting.length > 0) {
            getClaimsByCompanyId({ id: claimsByCompanyIdArgs.id, params: {
                ...claimsByCompanyIdArgs.params,
                sortBy: sorting[0].id,
                sortDirection: sorting[0].desc ? "DESC" : "ASC"
            } });
        }
    }, [claimsByCompanyIdArgs, getClaimsByCompanyId, sorting]);

    useEffect(() => {
        if (isCreateInvoiceError) {
            createInvoiceFailed();
        }
    }, [isCreateInvoiceError, createInvoiceFailed]);

    if (isInvoiceCreated && createdInvoice) {
        navigate(`/invoice/${createdInvoice.invoiceId}`, {
            state: { invoiceSummary: createdInvoice }
        });
    }

    const handleGetClaimInfo = useCallback(async() => {
        let payload;
        try {
            payload = await getClaimsInfo(claimIdRef.current).unwrap();
        } catch (error) {
            createInvoiceFailed();
            return;
        }

        if (payload && payload.subClaims?.length > 1) {
            onOpenSubclaimsModal();
        }

        if (payload && payload.subClaims?.length === 1) {
            createInvoice({
                invoiceQueryDto: {
                claimId: claimIdRef.current,
                subClaimId: payload.subClaims[0].gardSubClaimRef
            }, companyId: claimsByCompanyIdArgs?.id });
        }
    }, [getClaimsInfo, createInvoiceFailed, onOpenSubclaimsModal, createInvoice, claimsByCompanyIdArgs?.id]);

    const handleResetClaimId = useCallback(() => {
        claimIdRef.current = "";
    }, []);

    const handleCloseSubclaimsModal = useCallback(() => {
        claimIdRef.current = "";
        onCloseSubclaimsModal();
    }, [onCloseSubclaimsModal]);

    const handleCreateInvoiceClick = useCallback(
        (subClaimId: string) => {
            createInvoice({ invoiceQueryDto: {
                claimId: claimIdRef.current, subClaimId
            }, companyId: claimsByCompanyIdArgs?.id });
        },
        [claimsByCompanyIdArgs?.id, createInvoice]
    );

    const resetPagination = useCallback(() => {
        setPagination({ pageSize: claimsByCompanyIdArgs?.params.limit ?? DEFAULT_QUERY_FILTER.limit, pageIndex: 1 });
    }, [claimsByCompanyIdArgs?.params.limit]);

    return (
        <Box w="100%" mt="3rem">
            <SubclaimsModal
                isOpen={isSubclaimsModalOpen}
                claimIdRef={claimIdRef}
                isCreatingInvoice={isCreatingInvoice}
                claimsInfo={claimsInfo}
                reset={handleResetClaimId}
                onClose={handleCloseSubclaimsModal}
                onCreateInvoiceClick={handleCreateInvoiceClick}
            />
            <InvoiceAddressModal
                isOpen={isInvoiceAddressModalOpen}
                onClose={onCloseInvoiceAddressModal}
                claimIdRef={claimIdRef}
            />
            <Flex w="100%" gap="2rem" flexDir="column">
                <ClaimsSearch
                    getClaimsByCompanyId={getClaimsByCompanyId}
                    isFetchingClaims={isFetchingClaims}
                    isClaimsSearchError={isClaimsSearchError}
                    claimsByCompanyIdArgs={claimsByCompanyIdArgs}
                    resetPagination={resetPagination}
                />
                <Container
                    display="grid"
                    style={{ gridTemplateColumns: "repeat(1, minmax(0, 1fr))" }}
                    overflowX="auto"
                    maxW="100%"
                >
                    <ClaimsTable
                        searchResult={claimsSearchResult}
                        setPagination={setPagination}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        claimIdRef={claimIdRef}
                        invoiceArgs={invoiceArgs?.invoiceQueryDto}
                        createInvoice={handleGetClaimInfo}
                        claimsInfoArgs={claimsInfoArgs}
                        creatingInvoice={isCreatingInvoice}
                        isLoadingClaimsInfo={isLoadingClaimsInfo}
                        isFetchingClaims={isFetchingClaims}
                        onOpenInvoiceAddressModal={onOpenInvoiceAddressModal}
                        sorting={sorting}
                        setSorting={setSorting}
                    />
                </Container>
            </Flex>
        </Box>
    );
};
