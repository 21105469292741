import { Tooltip, Flex, Spinner, Text } from "@chakra-ui/react";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CloudCheckmarkIcon from "./CloudCheckmarkIcon";
import CloudOfflineIcon from "./CloudOfflineIcon";

type SaveStatusProps = {
    isSaving: boolean,
    isSavingError: boolean
    isSaved: boolean,
    lastSavedRef: React.MutableRefObject<number | null>
};

const SavedStatuses = ({ isSaving, isSavingError, lastSavedRef }: SaveStatusProps) => {
    const { t } = useTranslation("invoices");
    const [lastSavedMsg, setLastSavedMsg] = useState<string>();

    const getLastSaved = useCallback((isError?: boolean) => {
        if (!isError && lastSavedRef.current) {
            const SECONDS_IN_MINUTE = 60;
            const MILLISECONDS_TO_SECONDS = 1000;
            const currentTime = Date.now();
            const lastSavedTime = lastSavedRef.current;
            const differenceInMilliseconds = currentTime - lastSavedTime;
            const differenceInSeconds = Math.floor(differenceInMilliseconds / MILLISECONDS_TO_SECONDS);
            let timeAgoMessage = t("savedStatuses.lastSaved");

            if (differenceInSeconds < 1) {
                timeAgoMessage += t("savedStatuses.lastSavedJustNow");
                setLastSavedMsg(timeAgoMessage);
            }
            if (differenceInSeconds < SECONDS_IN_MINUTE) {
                // If less than 60 seconds, display in seconds
                timeAgoMessage += t("savedStatuses.lastSavedSeconds", { seconds: differenceInSeconds });
            } else {
                // If 60 seconds or more, convert to minutes and display
                const differenceInMinutes = Math.floor(differenceInSeconds / SECONDS_IN_MINUTE);
                timeAgoMessage += t("savedStatuses.lastSavedMinutes", { minutes: differenceInMinutes });
            }
            setLastSavedMsg(timeAgoMessage);
        }

        if (isError) {
            setLastSavedMsg(t("savedStatuses.errorDescription") + "claimsinvoicing@gard.no");
        }
    }, [lastSavedRef, t]);

    useEffect(() => {
        getLastSaved();
    }, [getLastSaved]);

    if (isSavingError) {
        return (
            <Tooltip
                label={lastSavedMsg}
                bg="anchor.green.600"
                hasArrow placement="top"
            >
                <Flex alignItems="center" gap="0.5rem" onMouseOver={() => getLastSaved(true)}>
                    <CloudOfflineIcon color="anchor.gray.900" />
                    <Text color="anchor.red.800" data-testid="errorSavingText">
                        {t("savedStatuses.errorSaving")}
                    </Text>
                </Flex>
            </Tooltip>
        );
    }

    if (isSaving) {
        return (
            <Flex alignItems="center" gap="0.5rem">
                <Spinner color="anchor.gray.900" />
                <Text color="anchor.gray.900" data-testid="savingText">{t("savedStatuses.saving")}</Text>
            </Flex>
        );
    }

    return (
        <Tooltip
            label={lastSavedMsg}
            bg="anchor.green.600"
            hasArrow placement="top"
        >
            <Flex alignItems="center" gap="0.5rem" onMouseOver={() => getLastSaved()}>
                <CloudCheckmarkIcon
                    w="full"
                    h="full"
                    color="anchor.gray.900"
                />
                <Text color="anchor.gray.900" data-testid="savedText">{t("savedStatuses.saved")}</Text>
            </Flex>
        </Tooltip>
    );
};

export default SavedStatuses;
