import i18n from "../../i18n";
import { MemberDetailsDto } from "../domain/invoice";

// Disables cognitive complexity as the cognitive complexity in this case only changes undefined text to empty string.
// eslint-disable-next-line sonarjs/cognitive-complexity
const createInvoiceAddressText = (memberDetails: MemberDetailsDto | undefined) => {
    const missingAddress = i18n.t("invoices:createInvoice.generalInfo.missingAddress");
    if (!memberDetails || !memberDetails.address) {
        return missingAddress;
    }

    let addr = `
        ${memberDetails.name}
        ${memberDetails.address?.addressLine1 ?? ""}
        ${memberDetails.address?.addressLine2 ?? ""}
        ${memberDetails.address?.addressLine3 ?? ""}
        ${memberDetails.address?.addressLine4 ?? ""}
        ${memberDetails.address?.zip ?? ""} ${memberDetails.address?.city ?? ""}
        ${memberDetails.address?.countryName ?? ""}`;
    if (memberDetails.vatNumber) {
        addr += `
            VAT No: ${memberDetails.vatNumber ?? ""}
        `;
    }
    if (memberDetails.coAddress) {
        addr += `
        c/o ${memberDetails.coAddress?.addressLine1 ?? ""}
        ${memberDetails.coAddress?.addressLine2 ?? ""}
        ${memberDetails.coAddress?.addressLine3 ?? ""}
        ${memberDetails.coAddress?.addressLine4 ?? ""}
        ${memberDetails.coAddress?.zip} ${memberDetails.coAddress?.city ?? ""}
        ${memberDetails.coAddress?.countryName ?? ""}`;
    }

    // Remove leading and trailing whitespace and newlines
    return addr.trim().replace(/(^([^\S]+))|(([^\S]+)$)/gm, "");
};

export { createInvoiceAddressText };
