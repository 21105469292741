import { FetchArgs, fetchBaseQuery, retry } from "@reduxjs/toolkit/dist/query";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { createStandaloneToast } from "@chakra-ui/react";
import { Middleware, MiddlewareAPI, isRejectedWithValue } from "@reduxjs/toolkit";

import { getUserInfo } from "../utils/oidcUtil";
import { logout } from "../store/authSlice";

const UNAUTHORIZED_STATUS_CODE = 401;
const REFETCH_INTERVAL = 30;
const DEFAULT_RETRY_COUNT = 3;

const staggeredBaseQueryWithBailOut = retry(
    async(args: string | FetchArgs, api, extraOptions) => {
        const result = await fetchBaseQuery({
            baseUrl: import.meta.env.VITE_API_BASE_URL + "/cia/api/v1",
            prepareHeaders: headers => {
                const token = getUserInfo()?.access_token;
                if (token) {
                    headers.set("Authorization", `Bearer ${token}`);
                }
                return headers;
            }
        })(args, api, extraOptions);

        if (result.error?.status === UNAUTHORIZED_STATUS_CODE) {
            retry.fail(result.error);
        }

        return result;
    },
    {
        maxRetries: import.meta.env.VITE_RETRY_COUNT ? import.meta.env.VITE_RETRY_COUNT : DEFAULT_RETRY_COUNT
    }
);

export const { ToastContainer, toast } = createStandaloneToast();

export const rtkQueryAuthExpire: Middleware = (api: MiddlewareAPI) => next => action => {
    if (isRejectedWithValue(action) && action.payload?.status === UNAUTHORIZED_STATUS_CODE) {
        api.dispatch(logout());
    }

    return next(action);
};

export const claimsInvoicingApi = createApi({
    baseQuery: staggeredBaseQueryWithBailOut,
    refetchOnMountOrArgChange: REFETCH_INTERVAL,
    endpoints: () => ({})
});

export type QueryFilter = {
    query?: string;
    page: number;
    limit: number;
    sortDirection: "ASC" | "DESC";
    sortBy?: string;
    status?: "Open" | "Closed";
};

export type SearchResultDto<T> = {
    items: T[];
    returnedResults: number;
    page: number;
    totalResults: number;
};
