import { UserManagerSettings } from "oidc-client-ts";
import { AuthProvider as OidcAuthProvider } from "react-oidc-context";

import oidcJson from "../../../mockData/idToken.json";
import { onSigninCallback } from "../../utils/oidcUtil";

/* eslint-disable */
export const oidcConfig: UserManagerSettings = {
    authority: import.meta.env.VITE_OAUTH_AUTHORITY,
    client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
    redirect_uri: import.meta.env.VITE_OAUTH_REDIRECT_URI,
    response_type: import.meta.env.VITE_OAUTH_RESPONSE_TYPE,
    scope: import.meta.env.VITE_OAUTH_SCOPE,
    refreshTokenAllowedScope: "",
    automaticSilentRenew: true
};
/* eslint-enable */

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    // Comment out the lines below to run the app in development mode without mocking the API
    if (import.meta.env.MODE === "development") {
        sessionStorage.setItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`, JSON.stringify(oidcJson));
    }

    return (
        <OidcAuthProvider
            {...oidcConfig}
            onSigninCallback={onSigninCallback}
        >
            {children}
        </OidcAuthProvider>
    );
};

export { AuthProvider };
