import { claimsInvoicingApi } from "./claimsInvoicingApi";

export const usersApi = claimsInvoicingApi.injectEndpoints({
    endpoints: builder => ({
        getHierarchy: builder.query<
            HierarchicalDto[],
            string
        >({
            query: () => ({
                url: '/users/hierarchy'
            })
        })
    })
});

export const {
    useGetHierarchyQuery
} = usersApi;


export type HierarchicalDto = {
    companyName: string;
    crmId: number | null;
    parentCrmId: number | null;
    billingCity: string;
};
