import { useCallback, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, Image, SimpleGrid, Spacer, Spinner, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LogoutIcon, UserIcon } from "@digital-services-gard-as/anchor-chakra";

import gardLogo from "./../assets/gard_logo.png";
import { AppDispatch, RootState } from "../App";
import { login } from "../store/authSlice";

const Layout = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isAuthExpired = useSelector((state: RootState) => state.auth.isAuthExpired);
    const dispatch: AppDispatch = useDispatch();
    const toast = useToast();

    const auth = useAuth();

    const handleSignin = useCallback(() => {
        dispatch(login());
        auth.clearStaleState();
        auth.signinRedirect({ state: window.location.href });
    }, [auth, dispatch]);

    const handleSignout = useCallback(() => {
        /* eslint-disable-next-line */
        auth.signoutRedirect({ redirectMethod: "replace", post_logout_redirect_uri: import.meta.env.BASE_URL + "/" });
    }, [auth]);

    const handleNavigate = useCallback(() => {
        navigate("/");
    }, [navigate]);

    useEffect(() => {
        if (auth.error) {
            auth.removeUser();
        }
    }, [auth]);

    useEffect(() => {
        if (isAuthExpired && auth.user) {
            toast({
                title: t("sessionExpiredTitle"),
                description: t("sessionExpiredDesc"),
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "top"
            });
            auth.removeUser();
        }
    }, [auth, isAuthExpired, toast, t]);

    return (
        <Flex
            as="nav"
            w="100%"
            wrap="wrap"
            backgroundColor="white"
            justifyContent="center"
        >
            <Box w="80%" maxW="100rem">
                <Flex h="5rem" justify="space-between" w="100%" align="center">
                    <Image
                        src={gardLogo}
                        alt="Gard logo"
                        className="gardLogo"
                        maxHeight="70%"
                        objectFit="cover"
                        onClick={handleNavigate}
                        _hover={{ cursor: "pointer" }}
                    />
                    <Flex alignItems={"center"} gap={"1rem"}>
                        <Navbar
                            isAuthenticated={auth.isAuthenticated}
                            profileName={auth.user?.profile.name}
                            onSignout={handleSignout}
                            onSignin={handleSignin}
                            isAuthLoading={auth.isLoading}
                        />
                    </Flex>
                </Flex>
            </Box>
        </Flex>
    );
};

type NavbarProps = {
    isAuthenticated: boolean;
    profileName?: string;
    onSignout: () => void;
    onSignin: () => void;
    isAuthLoading: boolean;
}

const Navbar = ({
    isAuthenticated,
    profileName,
    onSignout,
    onSignin,
    isAuthLoading
}: NavbarProps) => {
    const { t } = useTranslation();

    if (isAuthenticated) {
        return (
            <SimpleGrid columns={{ sm: 1, lg: 2 }} spacing={{ sm: 2, lg: 6 }}>
                {isAuthLoading ? (
                    <Flex alignItems="center">
                        <Spinner />
                    </Flex>
                ) : (
                    <Flex gap={2} alignItems={"center"}>
                        <UserIcon />
                        <Text id="profileName">{profileName}</Text>
                    </Flex>
                )}
                <Button
                    id="signoutBtn"
                    onClick={onSignout}
                    variant="outline"
                    leftIcon={<LogoutIcon />}
                    tabIndex={0}
                >{t("logout")}</Button>
            </SimpleGrid>
        );
    } else {
        return (
            <Flex justifyContent={"end"} alignItems={"center"} w={"100%"} gap={5}>
                <Spacer />
                <Button id="login" variant={"link"} onClick={onSignin}>{t("login")}</Button>
            </Flex>
        );
    }
};

export default Layout;
