import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction } from "react-router-dom";

type UseSubmissionProps = {
    isSubmitSuccess: boolean;
    toast: (input: any) => void;
    navigate: NavigateFunction;
    invoiceId: string | undefined;
    isSubmitError: boolean;
};

const useSubmission = ({ isSubmitSuccess, toast, navigate, invoiceId, isSubmitError }: UseSubmissionProps) => {
    const { t } = useTranslation("invoices");
    useEffect(() => {
        if (isSubmitSuccess) {
            toast({
                id: "invoiceSubmitted",
                title: t("invoices:createInvoice.invoiceSubmitted"),
                status: "success",
                isClosable: true,
                position: "top"
            });
            navigate("/", {
                state: {
                    submittedInvoiceId:  invoiceId
                }
            });
        }
        if (isSubmitError) {
            toast({
                id: "invoiceSubmitFailed",
                title: t("invoices:createInvoice.invoiceSubmitFailed"),
                status: "error",
                isClosable: true,
                position: "top"
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitSuccess, invoiceId, isSubmitSuccess, isSubmitError]);
};

export default useSubmission;
