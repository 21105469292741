import { Container, Tooltip } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

type TruncatedTextProps = {
    text: string;
}

export const TruncatedText = ({ text }: TruncatedTextProps) => {
    const textElementRef = useRef<HTMLDivElement | null>(null);

    const compareSize = () => {
        const scrollWidth = textElementRef?.current?.scrollWidth;
        const clientWidth = textElementRef?.current?.clientWidth;

        if (!scrollWidth || !clientWidth) {
            return;
        }
        const compare = scrollWidth > clientWidth;
        setHoverStatus(compare);
    };

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, []);

    useEffect(() => () => {
        window.removeEventListener('resize', compareSize);
    }, []);

    const [hoverStatus, setHoverStatus] = useState(false);

    return (
        <Tooltip
            label={text}
            isDisabled={!hoverStatus}
            size="md"
        >
            <Container
                ref={textElementRef}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                p="0"
                m="0"
                h="100%"
                w="100%"
                minH="16px"
            >
                {text}
            </Container>
        </Tooltip>
    );
};
