import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/react";
import { EditIcon, CloseIcon } from "@digital-services-gard-as/anchor-chakra";

import { HierarchicalDto } from "../api/usersApi";
import { TruncatedText } from "./TableText";
import { t } from "i18next";
import { useFeature } from "flagged";
import { QueryFilter } from "../api/claimsInvoicingApi";
import { ClaimParams } from "../api/claimsApi";

type BranchCompanyProps = {
    selectedBranch: HierarchicalDto | null,
    setSelectedBranch: (branch: HierarchicalDto | null) => void,
    onOpenBranchModal: () => void,
    getClaimsByCompanyId: ({ id, params }: ClaimParams) => void,
    queryFilter: QueryFilter
}


const BranchCompany = ({ selectedBranch, setSelectedBranch, onOpenBranchModal,
    queryFilter, getClaimsByCompanyId }: BranchCompanyProps) => {
    const isShowBranchCompanyModalEnabled = useFeature("CIA-322-HierarchicalCompanies");

    if (!isShowBranchCompanyModalEnabled) {
        return;
    }

    if(selectedBranch === null && window.sessionStorage.getItem("selectedBranch") !== null) {
        setSelectedBranch(JSON.parse(window.sessionStorage.getItem("selectedBranch")!));
    }

    const handleClearBranchCompany = () => {
        setSelectedBranch(null);
        window.sessionStorage.removeItem("selectedBranch");
        getClaimsByCompanyId({ params: { ...queryFilter } });
    };

    return (
        <Box>
            <Flex>
                <Text as="b">
                    {t("claimsInvoicesOverview:branchCompanyModal.description")}
                </Text>
            </Flex>
            {selectedBranch ? (
                <Flex
                    bg="anchor.brown.50"
                    p="0.5rem"
                    alignItems="center"
                    justifyContent="space-between"
                    w="18rem"
                    border="solid 1px"
                    borderColor="anchor.brown.200"
                    borderRadius="5px"
                >
                    <Flex maxW="75%">
                        <TruncatedText text={selectedBranch.companyName} />
                    </Flex>
                    <Flex gap={1}>
                        <IconButton
                            size="sm"
                            icon={<EditIcon size="sm" />}
                            aria-label="Edit branch company"
                            onClick={onOpenBranchModal}
                        />
                        <IconButton
                            size="sm"
                            icon={<CloseIcon />}
                            aria-label="Clear branch company"
                            onClick={handleClearBranchCompany}
                        />
                    </Flex>
                </Flex>
            ) : (
                <Flex>
                    <Button data-testid="branchModalBtn" onClick={onOpenBranchModal}>
                        {t(
                            "claimsInvoicesOverview:branchCompanyModal.selectBranchButton"
                        )}
                    </Button>
                </Flex>
            )}
        </Box>
    );
};

export default BranchCompany;
