import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    Container,
    Flex,
    Popover,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    Radio,
    RadioGroup,
    SimpleGrid,
    Stack,
    Text,
    VisuallyHidden
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { PaginationState, SortingState } from "@tanstack/react-table";

import { getExportedInvoices, useLazyGetInvoicesQuery } from "../../api/invoiceApi";
import { getUserInfo } from "../../utils/oidcUtil";
import { QueryFilter } from "../../api/claimsInvoicingApi";
import { InvoicesTable } from "./table/InvoicesTable";
import { Search } from "../../components/Search";
import { DownloadIcon, ExportIcon } from "@digital-services-gard-as/anchor-chakra";
import { useFeature } from "flagged";

type SubmittedInvoiceState = {
    state: {
        submittedInvoiceId: string;
    } | null;
};

const DEFAULT_QUERY_FILTER: QueryFilter = {
    query: "",
    page: 1,
    limit: 10,
    sortDirection: "DESC",
    sortBy: "invoiceDate"
};

export const InvoicesOverview = () => {
    const { t } = useTranslation(["claimsInvoicesOverview", "errorMessages"]);

    const { state } = useLocation() as SubmittedInvoiceState;
    const [queryFilter, setQueryFilter] = useState<QueryFilter>(DEFAULT_QUERY_FILTER);
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: DEFAULT_QUERY_FILTER.page,
        pageSize: DEFAULT_QUERY_FILTER.limit
    });
    const [sorting, setSorting] = useState<SortingState>([{ id: "invoiceDate", desc: true }]);
    const [exportOption, setExportOption] = useState<string>("all");
    const POLLING_INTERVAL = 10000;

    const isExportInvoicesEnabled = useFeature("CIA-989-ExportInvoices");

    const [getInvoices, {
        data: invoices,
        originalArgs: invoiceArgs,
        isFetching: isFetchingInvoices,
        isError: isInvoicesError
    }] = useLazyGetInvoicesQuery({ pollingInterval: POLLING_INTERVAL });

    useEffect(() => {
        if (getUserInfo() !== null) {
            getInvoices(DEFAULT_QUERY_FILTER);
        }
    }, [getInvoices]);

    useEffect(() => {
        if (invoiceArgs) {
            setQueryFilter(invoiceArgs);
        }
    }, [invoiceArgs]);

    useEffect(() => {
        if (!invoiceArgs) {
            getInvoices({
                ...DEFAULT_QUERY_FILTER,
                limit: pageSize,
                page: pageIndex
            });
            return;
        }

        getInvoices({
            ...invoiceArgs,
            limit: pageSize,
            page: pageIndex
        });
    }, [invoiceArgs, getInvoices, pageIndex, pageSize]);

    useEffect(() => {
        if (!invoiceArgs && sorting.length > 0) {
            getInvoices({
                ...DEFAULT_QUERY_FILTER,
                sortBy: sorting[0].id,
                sortDirection: sorting[0].desc ? "DESC" : "ASC"
            });
        }

        if (invoiceArgs && sorting.length > 0) {
            getInvoices({
                ...invoiceArgs,
                sortBy: sorting[0].id,
                sortDirection: sorting[0].desc ? "DESC" : "ASC"
            });
        }
    }, [invoiceArgs, getInvoices, sorting]);

    const resetPagination = useCallback(() => {
        if (!invoiceArgs) {
            setPagination({ pageSize: DEFAULT_QUERY_FILTER.limit, pageIndex: 1 });
            return;
        }

        setPagination({ pageSize: invoiceArgs.limit, pageIndex: 1 });
    }, [setPagination, invoiceArgs]);

    const handleKeyUp = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                resetPagination();
                getInvoices(queryFilter);
            }
        },
        [queryFilter, getInvoices, resetPagination]
    );

    const handleSearchTextChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setQueryFilter({
                ...queryFilter,
                query: e.target.value
            });
        },
        [queryFilter]
    );

    const handleSearchClick = useCallback(() => {
        resetPagination();
        getInvoices(queryFilter);
    }, [queryFilter, getInvoices, resetPagination]);

    const handleClearSearch = useCallback(() => {
        resetPagination();
        setQueryFilter({ ...queryFilter, query: "" });
        getInvoices({ ...queryFilter, query: "" });
    }, [getInvoices, queryFilter, resetPagination]);

    const handleExportInvoices = useCallback(() => {
        if (exportOption === "current") {
            const invoiceIds = invoices?.items.map(invoice => invoice.id);
            getExportedInvoices(invoiceIds, queryFilter);
            return;
        }
        getExportedInvoices([], queryFilter);
    }, [exportOption, invoices?.items, queryFilter]);

    return (
        <Flex mt="3rem" gap="2rem" flexDir="column" alignItems="center">
            { isInvoicesError && (
                <Alert
                    id="noDataAlert"
                    variant="error"
                    status="error"
                    minW="18rem"
                    maxW="40rem"
                    textAlign="left"
                >
                    <AlertIcon boxSize="40px" />
                    <AlertDescription>
                        {t("errorMessages:claimsInvoicesOverview.cannotRetrieveData")}
                    </AlertDescription>
                </Alert>
            )}
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={2} w="100%">
                <Flex>
                    <VisuallyHidden />
                </Flex>
                <Search
                    type="invoices"
                    placeholder={t("claimsInvoicesOverview:claimsSearchPlaceholder")}
                    queryFilter={queryFilter}
                    isFetchingData={isFetchingInvoices}
                    handleSearchCriteriaChange={handleSearchTextChange}
                    handleKeyUp={handleKeyUp}
                    handleSearchClick={handleSearchClick}
                    handleClearSearch={handleClearSearch}
                />
                <Flex justifyContent="end" gap="1rem" mr="1rem" alignItems="center">
                    { (invoices && invoices?.items?.length > 0) && isExportInvoicesEnabled ? (
                        <Popover placement="bottom-end">
                            <PopoverTrigger>
                                <Button
                                    variant="secondary"
                                    w="fit-content"
                                    leftIcon={<ExportIcon />}
                                    data-testid="invoicesExportBtn"
                                >
                                    {t("claimsInvoicesOverview:exportInvoices.title")}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverCloseButton />
                                <Flex flexDir="column" m="1rem">
                                    <Text textAlign="left" as="b">
                                        {t("claimsInvoicesOverview:exportInvoices.description")}
                                    </Text>
                                    <RadioGroup
                                        defaultValue="current"
                                        dir="column"
                                        pb="1rem"
                                        onChange={setExportOption}
                                        value={exportOption}
                                        data-testid="invoicesExportRadioGroup"
                                    >
                                        <Stack>
                                            <Radio value="all">
                                                {t("claimsInvoicesOverview:exportInvoices.allInvoices")}
                                            </Radio>
                                            <Radio value="current">
                                                {t("claimsInvoicesOverview:exportInvoices.currentInvoices")}
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                    <Button
                                        variant="secondary"
                                        leftIcon={<DownloadIcon />}
                                        onClick={handleExportInvoices}
                                        data-testid="invoicesExportDownloadBtn"
                                    >{t("claimsInvoicesOverview:exportInvoices.download")}</Button>
                                </Flex>
                            </PopoverContent>
                        </Popover>
                    ) : (
                        <VisuallyHidden />
                    )}
                </Flex>
            </SimpleGrid>
            <Container
                display="grid"
                style={{ gridTemplateColumns: "repeat(1, minmax(0, 1fr))" }}
                overflowX="auto"
                maxW="100%"
                minW="50rem"
            >
                <InvoicesTable
                    invoicesResult={invoices}
                    submittedInvoiceId={state?.submittedInvoiceId}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    setPagination={setPagination}
                    sorting={sorting}
                    setSorting={setSorting}
                    isFetchingInvoices={isFetchingInvoices}
                />
            </Container>
        </Flex>
    );
};
