import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";


const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
    return (error as FetchBaseQueryError).status !== undefined;
};

const isSerializedError = (error: any): error is SerializedError => {
    return (error as SerializedError).name !== undefined;
};

export { isFetchBaseQueryError, isSerializedError as isSerializeError };
