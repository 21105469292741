import { Icon } from "@chakra-ui/react";
import { FC } from "react";
import { GardIconProps } from "@digital-services-gard-as/anchor-chakra/types/generic/icons";

// TODO: This icon should be renewed when equivalent icons are implemented in Anchor Design System
const CloudCheckmarkIcon: FC<GardIconProps> = ({
    size = "md",
    fontSize = "1.5rem",
    ...props
}) => {
    const viewBox = size === "sm" ? "0 0 16 16" : "0 0 24 24";
    const dimension = size === "sm" ? "1em" : fontSize;

    const pathData = size === "sm"
            // eslint-disable-next-line max-len
            ? "M11.964 13a3.12 3.12 0 0 0 1.37-.313 3.006 3.006 0 0 0 1.074-.876c.283-.37.47-.797.55-1.25a2.828 2.828 0 0 0-.095-1.355 2.907 2.907 0 0 0-.718-1.167 3.047 3.047 0 0 0-1.188-.73 3.134 3.134 0 0 0-2.711.36 4.445 4.445 0 0 0-1.177-2.242A4.7 4.7 0 0 0 6.83 4.143a4.825 4.825 0 0 0-2.603.076 4.673 4.673 0 0 0-2.155 1.41 4.423 4.423 0 0 0-1.035 2.306 4.36 4.36 0 0 0 .407 2.482 4.556 4.556 0 0 0 1.724 1.885c.748.457 1.615.7 2.501.698h6.295Z"
            : "M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 1 0 0-10Z";

    const pathData2 = size === "sm"
            ? "M3.5 8.5 5 10l2.5-2.5"
            : "m6 12 2.25 3L12 10";
    return (
        <Icon
            {...props}
            viewBox={viewBox}
            width={dimension}
            height={dimension}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d={pathData}
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d={pathData2}
            />
        </Icon>
    );
};

export default CloudCheckmarkIcon;
