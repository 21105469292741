import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";

import { bankDetailsSchema } from "../../../validation/validation";
import currencies from "../../../utils/currencies.json";
import { BankAccountDto } from "../../../domain/invoice";

type BankDetailsProps = {
    invoiceId: string,
    isOpen: boolean,
    onClose: () => void,
    addBankAccount: ({ id, bankAccount }: { id: string, bankAccount: BankAccountDto }) => void;
}

type BankDetailsForm = {
    bankName: string,
    swiftBicCode: string,
    currency: string,
    accountNumber: string,
    accountHolder: string,
    branchNumber: string,
    correspondingBank: string
}

export const BankDetailsModal = ({ invoiceId, isOpen, onClose, addBankAccount }: BankDetailsProps) => {
    const [submitError, setSubmitError] = useState<string>("");
    const methods = useForm<BankDetailsForm>({
        resolver: zodResolver(bankDetailsSchema()),
        mode: "onBlur"
    });
    const { formState: { errors: formErrors, isValid }, register, handleSubmit } = methods;
    const { t } = useTranslation(["invoices", "errorMessages"]);

    const onSubmit: SubmitHandler<BankDetailsForm> = data => {
        const bankAccount = {
            ...data,
            // eslint-disable-next-line no-magic-numbers
            currency: data.currency.substring(0, 3)
        };
        addBankAccount({ id: invoiceId, bankAccount });
        onClose();
    };

    const onErrorSubmit: SubmitErrorHandler<BankDetailsForm> = errors => {
        if (!isValid) {
            const amountOfErrors = Object.keys(errors).length;
            setSubmitError(amountOfErrors === 0 ? ""
                : t("errorMessages:invoiceForm.errorAmount", { amount: amountOfErrors }) as string);
        }
    };

    const handleCloseModalClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onClose();
    }, [onClose]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent data-testid="bankDetailsModal">
                <ModalHeader>{t("invoices:createInvoice.bankDetails.newAccountTitle")}</ModalHeader>
                <ModalCloseButton data-testid="bankDetailsModalCloseBtn" />
                <ModalBody>
                    <Text>{t("invoices:createInvoice.bankDetails.newAccountDesc")}</Text>
                    <form onSubmit={handleSubmit(onSubmit, onErrorSubmit)}>
                        <Flex flexDir="column" mt="2rem" gap="2.5rem">
                            <FormControl isInvalid={!!formErrors.bankName?.message} maxW="20rem">
                                <FormLabel>{t("invoices:createInvoice.bankDetails.bankName")}</FormLabel>
                                <Input id="bankName" {...register("bankName")} />
                                <FormErrorMessage data-testid="bankNameError">
                                    {formErrors.bankName?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.swiftBicCode?.message} maxW="10rem">
                                <FormLabel>{t("invoices:createInvoice.bankDetails.swiftBicCode")}</FormLabel>
                                <Input id="swiftBicCode" {...register("swiftBicCode")} />
                                <FormErrorMessage data-testid="swiftBicCodeError">
                                    {formErrors.swiftBicCode?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.currency?.message}>
                                <FormLabel>{t("invoices:createInvoice.bankDetails.currency")}</FormLabel>
                                <Select id="currencySelect" {...register("currency")} placeholder="Select currency">
                                    {Object.entries(currencies).map(([key, value]) => (
                                        <option key={key} value={key}>{key}: {value}</option>
                                    ))}
                                </Select>
                                <FormErrorMessage data-testid="currencyError">
                                    {formErrors.currency?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.accountNumber?.message} maxW="25rem">
                                <FormLabel>{t("invoices:createInvoice.bankDetails.accountNumber")}</FormLabel>
                                <Input id="accountNumber" {...register("accountNumber")} />
                                <FormErrorMessage data-testid="accountNumberError">
                                    {formErrors.accountNumber?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.accountHolder?.message} maxW="20rem">
                                <FormLabel>{t("invoices:createInvoice.bankDetails.accountHolder")}</FormLabel>
                                <Input id="accountHolder" {...register("accountHolder")} />
                                <FormErrorMessage data-testid="accountHolderError">
                                    {formErrors.accountHolder?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.branchNumber?.message} maxW="15rem">
                                <FormLabel>{t("invoices:createInvoice.bankDetails.branchNumber")}</FormLabel>
                                <Input id="branchNumber" {...register("branchNumber")} />
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.correspondingBank?.message} maxW="20rem">
                                <FormLabel>{t("invoices:createInvoice.bankDetails.correspondingBank")}</FormLabel>
                                <Input id="correspondingBank" {...register("correspondingBank")} />
                            </FormControl>
                            <Flex gap={"1rem"}>
                                <Button
                                    id="submitBtn"
                                    variant="solid"
                                    type="submit"
                                >
                                    {t("invoices:createInvoice.bankDetails.addBankAccountBtn")}
                                </Button>
                                <Button
                                    id="closeBankAccountModalBtn"
                                    variant="outline"
                                    onClick={handleCloseModalClick}
                                >
                                    {t("invoices:createInvoice.bankDetails.closeBankAccountModalBtn")}
                                </Button>
                            </Flex>
                            <Text color="red">{submitError}</Text>
                        </Flex>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
