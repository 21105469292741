import { HierarchicalDto } from "../api/usersApi";

export type InvoiceSummaryDto = {
    invoiceId: string,
    status: string,
    claimDetails?: ClaimDetailsDto,
    invoiceDetails?: InvoiceDetailsDto,
    invoiceSender?: CompanyDto,
    invoiceRecipient?: MemberDetailsDto
}

export type ClaimDetailsDto = {
    id: string,
    status: string,
    reportedDate: Date,
    description: string,
    vessel: string,
    vesselId: string,
    clientName: string,
    clientId: number,
    subClaims?: SubClaimDto[]
}

export type MemberDetailsDto = {
    name?: string,
    address?: AddressDto,
    vatNumber?: string,
    coAddress?: AddressDto
}

export type SubClaimDto = {
    id: string,
    status: string,
    description: string,
    claimId: string,
    gardSubClaimRef?: string,
    closeDate?: string,
    claimType: string,
    claimDetail: string
}

export type AddressDto = {
    addressLine1?: string,
    addressLine2?: string,
    addressLine3?: string,
    addressLine4?: string,
    city?: string,
    zip?: string,
    countryName?: string
}

export type BankAccountDto = {
    bankName: string,
    accountNumber: string,
    currency: string,
    swiftBicCode: string,
    localReferenceId?: string,
    accountHolder: string,
    branchNumber: string,
    correspondingBank: string
}

export type CompanyDto = {
    id: string,
    name: string,
    bankAccounts: BankAccountDto[],
    hierarchy?: HierarchicalDto
}

export type InvoiceDetailsDto = {
    id: string,
    invoiceDate?: string,
    invoiceDueDate?: string,
    incidentDate?: string,
    invoiceNumber?: string,
    subClaimsRef?: string,
    correspondentCosts?: number,
    surveyorCosts?: number,
    expertCosts?: number,
    lawyerFees?: number,
    lawyerExpenses?: number,
    totalVatAmount?: number,
    totalLocalTax?: number,
    otherExpenses?: number,
    correctAmountVerified: boolean,
    attachment?: AttachmentDto,
    isLastInvoice: boolean,
    bankAccount?: BankAccountDto,
    submittedDate?: string,
    advanceOfFunds?: number,
    previousAdvanceOfFunds?: number
}

export type AttachmentDto = {
    docId: string,
    filename: string,
    fileExtension: string,
    fileSize: number
}

/* eslint-disable no-unused-vars */
export enum InvoiceStatus {
    Draft,
    Submitted,
    Rejected,
    InProcess,
    Approved
}
/* eslint-enable no-unused-vars */

export type InvoiceAttachment = {
    filename: string,
    fileSize: number,
    fileExtension: string | undefined,
    isUploading: boolean,
    isUploaded: boolean,
    uploadFailed: boolean,
    file?: File,
    docId?: string
}

export type InvoiceFormData = {
    invoiceDate: string | undefined;
    invoiceDueDate: string | undefined;
    invoiceReference: string | undefined;
    bankAccount: BankAccountDto | undefined;
    invoiceAttachment: InvoiceAttachment | undefined;
    correspondentFees: string | undefined;
    surveyorFees: string | undefined;
    expertFees: string | undefined;
    lawyerFees: string | undefined;
    lawyerExpenses: string | undefined;
    totalAmountVAT: string | undefined;
    totalAmountLocalTaxes: string | undefined;
    otherExpenses: string | undefined;
    lastInvoice: string | undefined;
    addressConfirmation: boolean;
    totalConfirmation: boolean;
    hasAdvanceOfFunds?: boolean;
    advanceOfFunds: string | undefined;
    previousAdvanceOfFunds: string | undefined;
    personalSensitiveConfirmation: boolean;
};
