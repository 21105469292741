import { Flex, FormControl, FormLabel, Radio, RadioGroup, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { FormNumberInput } from "./FormNumberInput";
import { FieldErrors } from "react-hook-form";
import { InvoiceFormData } from "../../../../domain/invoice";

type AdvanceOfFundsProps = {
    handleRadioChange: (e: any) => void;
    errors: FieldErrors<InvoiceFormData>;
    control: any;
    currency: string | undefined;
    hasAdvanceOfFunds: boolean | undefined;
};

export const AdvanceOfFunds = ({
    errors,
    control,
    currency,
    hasAdvanceOfFunds,
    handleRadioChange
}: AdvanceOfFundsProps) => {
    const { t } = useTranslation(["invoices", "main"]);

    return (
        <Flex flexDir="column" gap="2.5rem">
            <FormControl>
                <FormLabel>{t("invoices:createInvoice.feesAndExpenses.advanceOfFundsQuestion")}</FormLabel>
                <RadioGroup value={hasAdvanceOfFunds?.toString()} data-testid="hasAdvanceOfFunds">
                    <VStack alignItems="left">
                        <Radio value="true" onChange={handleRadioChange}>{t("main:yes")}</Radio>
                        <Radio value="false" onChange={handleRadioChange}>{t("main:no")}</Radio>
                    </VStack>
                </RadioGroup>
            </FormControl>
            <ShowAdvanceOfFundsInputs
                errors={errors}
                control={control}
                currency={currency}
                hasAdvanceOfFunds={hasAdvanceOfFunds}
            />
        </Flex>
    );
};

type ShowAdvanceOfFundsInputsProps = {
    errors: FieldErrors<InvoiceFormData>;
    control: any;
    currency: string | undefined;
    hasAdvanceOfFunds: boolean | undefined;
};

const ShowAdvanceOfFundsInputs = ({ errors, control, currency, hasAdvanceOfFunds }: ShowAdvanceOfFundsInputsProps) => {
    const { t } = useTranslation("invoices");

    if (!hasAdvanceOfFunds) {
        return;
    }

    return (
        <Flex flexDir="column" gap="2.5rem">
            <FormNumberInput
                label={t("invoices:createInvoice.feesAndExpenses.advanceOfFunds")}
                id="advanceOfFunds"
                errorMessage={errors.advanceOfFunds?.message}
                isRequired={false}
                control={control}
                currency={currency}
                allowNegative={false}
            />
            <FormNumberInput
                label={t("invoices:createInvoice.feesAndExpenses.previousAdvanceOfFunds")}
                id="previousAdvanceOfFunds"
                errorMessage={errors.previousAdvanceOfFunds?.message}
                isRequired={false}
                control={control}
                currency={currency}
                allowNegative={false}
            />
    </Flex>
    );
};
