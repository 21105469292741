import { useEffect } from "react";
import { BankAccountDto, InvoiceDetailsDto, InvoiceFormData, InvoiceSummaryDto } from "../../domain/invoice";
import { AUTOSAVE_CYCLE, convertFormToInvoiceDetails } from "../../utils/invoiceUtils";
import { UseFormReturn } from "react-hook-form";

type UseAutosaveProps = {
    isFormChanged: boolean;
    methods: UseFormReturn<InvoiceFormData, any, undefined>;
    bankAccounts: BankAccountDto[];
    updateInvoice: any;
    setIsFormChanged: (isChanged: boolean) => void;
    lastSavedRef: React.MutableRefObject<number>;
    invoiceSummary: InvoiceSummaryDto;
};

const useAutosave = ({
    isFormChanged,
    methods,
    bankAccounts,
    updateInvoice,
    setIsFormChanged,
    lastSavedRef,
    invoiceSummary
}: UseAutosaveProps) => {
    useEffect(() => {
        const updateInvoiceOnChange = () => {
            if (isFormChanged) {
                const form = methods.getValues();
                const bankAccount = bankAccounts.filter(e => e.accountNumber === form.bankAccount?.accountNumber
                    && e.currency === form.bankAccount?.currency)[0];
                const invoice: InvoiceDetailsDto = convertFormToInvoiceDetails(
                    invoiceSummary.invoiceId, form, bankAccount
                );
                updateInvoice({
                    id: invoiceSummary.invoiceId,
                    invoice
                });
                setIsFormChanged(false);
                lastSavedRef.current = Date.now();
            }
        };
        const interval = setInterval(() => {
            updateInvoiceOnChange();
        }, AUTOSAVE_CYCLE);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormChanged]);
};

export default useAutosave;
