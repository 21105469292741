import { Icon } from "@chakra-ui/react";
import { FC } from "react";
import { GardIconProps } from "@digital-services-gard-as/anchor-chakra/types/generic/icons";

// TODO: This icon should be renewed when equivalent icons are implemented in Anchor Design System
const CloudOfflineIcon: FC<GardIconProps> = ({
    size = "md",
    fontSize = "1.5rem",
    ...props
}) => {
    const viewBox = size === "sm" ? "0 0 16 16" : "0 0 24 24";
    const dimension = size === "sm" ? "1em" : fontSize;

    const pathData = size === "sm"
            // eslint-disable-next-line max-len
            ? "M14.75 11.284c.203-.428.285-.894.236-1.357a2.619 2.619 0 0 0-.513-1.294 3.06 3.06 0 0 0-1.142-.925 3.48 3.48 0 0 0-1.497-.333h-.797c-.258-.904-.826-1.715-1.625-2.32A5.405 5.405 0 0 0 6.578 4m-2.967.562c-.987.486-1.764 1.252-2.207 2.175a4.038 4.038 0 0 0-.233 2.923c.29.97.938 1.825 1.839 2.43.9.606 2.003.926 3.131.91h5.693c.367-.001.73-.058 1.075-.17M1 1l14 14"
            // eslint-disable-next-line max-len
            : "M22.61 16.95A4.999 4.999 0 0 0 18 10h-1.26a8 8 0 0 0-7.05-6M5 5a8 8 0 0 0 4 15h9a5 5 0 0 0 1.7-.3M1 1l22 22";

    const pathData2 = size === "sm"
            ? "M0 0h16v16H0z"
            : "M0 0h24v24H0z";
    return (
        <Icon
            {...props}
            viewBox={viewBox}
            width={dimension}
            height={dimension}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                clip-path="url(#a)"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path d={pathData}/>
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d={pathData2} />
                </clipPath>
            </defs>
        </Icon>
    );
};

export default CloudOfflineIcon;
