import { Button, Card, CardBody, CardHeader, Flex, Heading, Icon, Link, ListItem, OrderedList, SimpleGrid, Text, UnorderedList } from "@chakra-ui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FiCheckCircle, FiSend, FiStar } from "react-icons/fi";
import { useAuth } from "react-oidc-context";


const ExternalLanding = () => {
    const { signinRedirect } = useAuth();
    const { t } = useTranslation("externalLanding");

    const handleSigninRedirect = useCallback(() => {
        signinRedirect();
    }, [signinRedirect]);

    return (
        <Flex flexDir="column" m="7rem" alignItems="center">
            <Flex flexDir="column" gap="2rem" maxW="28rem">
                <Heading size="xl">
                    {t("title")}
                    <br />
                    {t("title2")}
                </Heading>
                <Text fontSize="lg">{t("ingress")}</Text>
                <Button id="externalLandingLoginBtn" maxW="15rem" onClick={handleSigninRedirect}>
                    {t("loginBtn")}
                </Button>
            </Flex>
            {/* eslint-disable-next-line no-magic-numbers */ }
            <SimpleGrid columns={{ sm: 1, lg: 3 }} spacing={5} mt="5rem">
                <Card w="18rem">
                    <CardHeader>
                        <Flex alignItems="center" gap="0.5rem">
                            <Icon boxSize={6} as={FiStar} color="anchor.orange.500" />
                            <Heading size="md">{t("features.heading")}</Heading>
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <UnorderedList>
                            <ListItem>{t("features.invoiceStatus")}</ListItem>
                            {/*<ListItem>{t("features.findAndCopy")}</ListItem>*/}
                            <ListItem>{t("features.submitInvoiceOnBehalf")}</ListItem>
                            <ListItem>{t("features.trainingVideos")}</ListItem>
                        </UnorderedList>
                    </CardBody>
                </Card>
                <Card w="18rem">
                    <CardHeader>
                        <Flex alignItems="center" gap="0.5rem">
                            <Icon boxSize={6} color="anchor.green.500" as={FiCheckCircle} />
                            <Heading size="md">{t("threeStepProcess.heading")}</Heading>
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <Text pb="0.5rem">{t("threeStepProcess.invoice")}</Text>
                        <OrderedList>
                            <ListItem>{t("threeStepProcess.findRightClaim")}</ListItem>
                            <ListItem>{t("threeStepProcess.generalInfo")}</ListItem>
                            <ListItem>{t("threeStepProcess.totalExpense")}</ListItem>
                        </OrderedList>
                    </CardBody>
                </Card>
                <Card w="18rem">
                    <CardHeader>
                    <Flex alignItems="center" gap="0.5rem">
                            <Icon boxSize={6} as={FiSend} color="anchor.navy.500" />
                            <Heading size="md">{t("support.heading")}</Heading>
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <Text pb="0.5rem">{t("support.claims")}</Text>
                        <Text pb="0.5rem">{t("support.inNeed")}</Text>
                        <Link textDecoration="underline" href="mailto:claims.invoicing@gard.no">
                            claims.invoicing@gard.no
                        </Link>
                    </CardBody>
                </Card>
            </SimpleGrid>
            <SimpleGrid columns={{ sm: 1, lg: 3 }} spacing={5} mt="5rem">
                <iframe
                width="280"
                height="215"
                src="https://www.youtube.com/embed/EQCEoMrYs4U?si=yd17owC93fAdHt_C"
                title="YouTube video player"
                allow="accelerometer;
                autoplay; clipboard-write;encrypted-media; gyroscope; picture-in-picture;
                web-share"
                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
                <iframe
                width="280"
                height="215"
                src="https://www.youtube.com/embed/5y2JSv4-418?si=Ef9oGfCzFc3HZEZ4"
                title="YouTube video player"
                allow="accelerometer;
                autoplay; clipboard-write;encrypted-media; gyroscope; picture-in-picture;
                web-share"
                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
                <iframe
                width="280"
                height="215"
                src="https://www.youtube.com/embed/yuPpzF3l4f4?si=1jq1EiMn1jDZUA9t"
                title="YouTube video player"
                allow="accelerometer;
                autoplay; clipboard-write;encrypted-media; gyroscope; picture-in-picture;
                web-share"
                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
            </SimpleGrid>
        </Flex>
    );
};

export default ExternalLanding;
