import { FormControl, FormErrorMessage, FormLabel, IconButton, Input, InputGroup, InputRightAddon, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";
import { Controller } from "react-hook-form";
import { FiInfo } from "react-icons/fi";
import { NumericFormat } from "react-number-format";


type FormNumberInputProps = {
    label: string;
    id: string;
    isRequired: boolean;
    errorMessage: string | undefined;
    control: any;
    currency: string | undefined;
    allowNegative?: boolean;
    isDisabled?: boolean;
};

export const FormNumberInput = ({
    label,
    id,
    errorMessage,
    isRequired,
    control,
    currency,
    allowNegative = true,
    isDisabled = false
}: FormNumberInputProps) => {
    return (
        <FormControl isInvalid={!!errorMessage} isRequired={isRequired} maxW="15rem">
            <FormLabel>
                {label}
            </FormLabel>
            <Controller
                control={control}
                name={id}
                render={({ field }) => (
                    <InputGroup>
                        <Input
                            w="11rem"
                            id={id}
                            {...field}
                            as={NumericFormat}
                            thousandSeparator=","
                            decimalSeparator="."
                            allowNegative={allowNegative}
                            decimalScale={2}
                            ref={null}
                            autoComplete="off"
                            value={field.value || ''}
                            data-lpignore="true"
                            disabled={isDisabled}
                        />
                        <InputRightAddon
                            p="0 1rem"
                            bg="anchor.navy.50"
                        >{currency}</InputRightAddon>
                        {isDisabled && (
                            <Tooltip
                                hasArrow
                                label={t("invoices:createInvoice.feesAndExpenses.vatNotApplicable")}
                                placement="top"
                                >
                                <span>
                                    <IconButton
                                        size="xs"
                                        aria-label={t("invoices:createInvoice.feesAndExpenses.vatNotApplicable")}
                                        as={FiInfo}
                                        ref={null}
                                        variant="primary"
                                        bg="anchor.red.50"
                                        color="anchor.red.600"
                                        _hover={{
                                            bg: "anchor.red.100",
                                            color: "anchor.red.600"
                                        }}
                                        p="0.2rem"
                                        mt="0.5rem"
                                        ml="0.2rem"
                                        tabIndex={0}
                                    />
                                </span>
                            </Tooltip>
                        )}
                    </InputGroup>
                )}
            />
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
    );
};
