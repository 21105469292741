import { Table as ChakraTable, Thead, Tr, Th, Flex, Tbody } from "@chakra-ui/react";
import { ArrowUpIcon, ArrowDownIcon, SortOrderIcon } from "@digital-services-gard-as/anchor-chakra";
import { Table, flexRender } from "@tanstack/react-table";


type TableContentProps<T> = {
    table: Table<T>;
    children: React.ReactNode;
};

const TableContent = <T, >({ table, children }: TableContentProps<T>) =>
    <ChakraTable maxW="100%" boxSizing="border-box" whiteSpace="normal">
        <Thead
            position="sticky"
            top={0}
            zIndex={1}
            bg="anchor.white"
            borderBottom="1px"
            borderColor="anchor.gray.100"
            h="3rem"
            maxW="100%"
        >
            {table.getHeaderGroups().map(headerGroup => (
                <Tr key={headerGroup.id}
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    h="1.5rem"
                    maxW="100%"
                >
                    {headerGroup.headers.map(header => {
                        return (
                            <Th
                                key={header.id}
                                colSpan={header.colSpan}
                                textTransform="none"
                                h="1.5rem"
                                fontSize={"15px"}
                                onClick={header.column.getToggleSortingHandler()}
                                bg={header.column.getIsSorted() ? "anchor.brown.100" : "anchor.white"}
                                w={header.getSize() + "rem"}
                                wordBreak="break-word"
                                whiteSpace="normal"
                                data-testid={header.column.id + "Header"}
                            >
                                {header.isPlaceholder ? null : (
                                    <Flex
                                        className={
                                        header.column.getCanSort()
                                            ? 'cursor-pointer select-none'
                                            : ''
                                        }
                                        onClick={header.column.getToggleSortingHandler()}
                                        gap="1rem"
                                        alignItems="center"
                                        maxW="100%"
                                    >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                        {{
                                            asc: <ArrowUpIcon size="sm" />,
                                            desc: <ArrowDownIcon size="sm" />
                                        }[header.column.getIsSorted() as string] ?? null}
                                        {header.column.getCanSort() && !header.column.getIsSorted() && (
                                            <SortOrderIcon color="anchor.gray.300" />
                                        )}
                                    </Flex>
                                )}
                            </Th>
                        );
                    })}
                </Tr>
            ))}
        </Thead>
        <Tbody fontSize={"16px"} maxW="100%" minW="10rem">
            {children}
        </Tbody>
    </ChakraTable>;

export default TableContent;
