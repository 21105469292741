import { InvoiceDetailsDto, InvoiceFormData } from "../domain/invoice";


const isFieldsInvoiceDetailsDto = (fields: InvoiceFormData | InvoiceDetailsDto): fields is InvoiceDetailsDto =>
    (fields as InvoiceDetailsDto).correspondentCosts !== undefined;

const convertFieldsToNumber = (fields: InvoiceDetailsDto): number => {
    const fieldsAsNumber = {
        correspondentCosts: +(fields.correspondentCosts ?? 0),
        surveyorCosts: +(fields.surveyorCosts ?? 0),
        expertCosts: +(fields.expertCosts ?? 0),
        lawyerExpenses: +(fields.lawyerExpenses ?? 0),
        lawyerFees: +(fields.lawyerFees ?? 0),
        totalVatAmount: +(fields.totalVatAmount ?? 0),
        totalLocalTax: +(fields.totalLocalTax ?? 0),
        otherExpenses: +(fields.otherExpenses ?? 0),
        advanceOfFunds: +(fields.advanceOfFunds ?? 0),
        previousAdvanceOfFunds: -(fields.previousAdvanceOfFunds ?? 0)
    };
    return Object.values(fieldsAsNumber).reduce((sum, value) => {
        return sum + value;
    }, 0);
};

export const getTotalAmount = (fields: InvoiceFormData | InvoiceDetailsDto | undefined): number => {
    if (!fields) {
        return 0;
    }

    if (isFieldsInvoiceDetailsDto(fields)) {
        const fieldsAmount = convertFieldsToNumber(fields);
        return isNaN(fieldsAmount) ? 0 : fieldsAmount;
    }

    const subPreviousAdvanceOfFunds: string = (-convertCostToNumber(fields.previousAdvanceOfFunds)).toString();

    const fieldsArray = [
        fields.correspondentFees,
        fields.surveyorFees,
        fields.expertFees,
        fields.lawyerExpenses,
        fields.lawyerFees,
        fields.otherExpenses,
        fields.totalAmountVAT,
        fields.totalAmountLocalTaxes,
        fields.advanceOfFunds,
        subPreviousAdvanceOfFunds
      ];
      return fieldsArray.reduce((sum, field) => sum + convertCostToNumber(field), 0);
};

export const convertCostToNumber = (field: string | undefined) => {
    if (!field) {
        return 0;
    } else {
        return Number(field.replaceAll(",", ""));
    }
};
