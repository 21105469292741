/* eslint-disable camelcase */
/* eslint-disable no-magic-numbers */
import { z } from "zod";

import i18next from "../../i18n";

export const registerUserSchema = () => {
    const firstNameRequired: string = i18next.t(
        "errorMessages:registerForm.firstNameRequired"
    );
    const lastNameRequired: string = i18next.t(
        "errorMessages:registerForm.lastNameRequired"
    );
    const emailInvalid: string = i18next.t("errorMessages:registerForm.emailInvalid");
    const emailRequired: string = i18next.t("errorMessages:registerForm.emailRequired");

    return z.object({
        users: z.array(
            z.object({
                firstName: z.string().min(1, firstNameRequired),
                lastName: z.string().min(1, lastNameRequired),
                email: z.string().email(emailInvalid).min(1, emailRequired)
            })
        )
    });
};

export const invoiceSchema = () => {
    const invalidDate: string = i18next.t("errorMessages:invoiceForm.invalidDate");
    const futureDate: string = i18next.t("errorMessages:invoiceForm.futureDate");
    const invalidDueDate: string = i18next.t("errorMessages:invoiceForm.invalidDueDate");
    const invoiceReferenceMin: string = i18next.t(
        "errorMessages:invoiceForm.invoiceReferenceMin"
    );
    // const lastInvoice: string = i18next.t("errorMessages:invoiceForm.lastInvoice");
    const totalConfirmation: string = i18next.t(
        "errorMessages:invoiceForm.totalConfirmation"
    );
    const addressConfirmation: string = i18next.t(
        "errorMessages:invoiceForm.addressConfirmation"
    );
    const personalSensitiveConfirmation: string = i18next.t(
        "errorMessages:invoiceForm.personalSensitiveConfirmation"
    );

    return z.object({
        invoiceDate: z.coerce.date({
            errorMap: ({ code }, { defaultError }) => {
                if (code === 'invalid_date') {
                    return { message: invalidDate };
                }
                return { message: defaultError };
            }
        }).refine(val => val < new Date(), { message: futureDate }),
        invoiceDueDate: z.coerce.date({
            errorMap: ({ code }, { defaultError }) => {
                if (code === 'invalid_date') {
                    return { message: invalidDate };
                }
                return { message: defaultError };
            }
        }).refine(val => {
            const today = new Date();
            const dueDate = new Date(val);
            const validDueDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
            return dueDate >= validDueDate;
        }, { message: invalidDueDate }),
        invoiceReference: z.nullable(z.string().min(1, invoiceReferenceMin)),
        // lastInvoice: z.string({ required_error: lastInvoice }),
        totalConfirmation: z.coerce.boolean().refine(val => val, {
            message: totalConfirmation
        }),
        addressConfirmation: z.coerce.boolean().refine(val => val, {
            message: addressConfirmation
        }),
        personalSensitiveConfirmation: z.coerce.boolean().refine(val => val, {
            message: personalSensitiveConfirmation
        }),
        invoiceAttachment: z.object({
            file: z.any().optional(),
            docId: z.string().optional(),
            filename: z.string().optional(),
            fileSize: z.number().optional(),
            fileExtension: z.string().optional(),
            isUploading: z.boolean().optional(),
            isUploaded: z.boolean().optional(),
            uploadFailed: z.boolean().optional()
        })
    });
};

export const bankDetailsSchema = () => {
    const bankNameError: string = i18next.t(
        "errorMessages:bankDetailsForm.bankNameError"
    );
    const swiftBicCodeError: string = i18next.t(
        "errorMessages:bankDetailsForm.swiftBicCodeError"
    );
    const currencyError: string = i18next.t(
        "errorMessages:bankDetailsForm.currencyError"
    );
    const accountNumberError: string = i18next.t(
        "errorMessages:bankDetailsForm.accountNumberError"
    );
    const accountHolderError: string = i18next.t(
        "errorMessages:bankDetailsForm.accountHolderError"
    );
    return z.object({
        bankName: z.string().min(1, bankNameError),
        swiftBicCode: z.string().min(1, swiftBicCodeError),
        currency: z.string().min(1, currencyError),
        accountNumber: z.string().min(1, accountNumberError),
        accountHolder: z.string().min(1, accountHolderError),
        correspondingBank: z.string().optional(),
        branchNumber: z.string().optional()
    });
};
