import React, { MutableRefObject, useCallback, useMemo } from "react";
import {
    Text,
    Flex,
    Tooltip,
    Badge,
    Icon,
    IconButton,
    TableContainer,
    chakra
} from "@chakra-ui/react";
import {
    useReactTable,
    ColumnDef,
    getCoreRowModel,
    getExpandedRowModel,
    CellContext,
    PaginationState,
    OnChangeFn,
    SortingState
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { FiChevronDown, FiChevronUp, FiCopy } from "react-icons/fi";
import { Pagination } from "@digital-services-gard-as/anchor-chakra/src";
import { useFeature } from "flagged";

import { ClaimsItemDto } from "../../../api/claimsApi";
import { SearchResultDto } from "../../../api/claimsInvoicingApi";
import { InvoiceQueryDto } from "../../../api/invoiceApi";
import { TruncatedText } from "../../../components/TableText";
import ClaimsTableRow from "./ClaimsTableRow";
import CreateInvoiceButton from "./CreateInvoiceButton";
import TableContent from "../../../components/TableContent";

type ClaimsTableProps = {
    searchResult: SearchResultDto<ClaimsItemDto> | undefined,
    claimIdRef: MutableRefObject<string>,
    invoiceArgs: InvoiceQueryDto | undefined,
    claimsInfoArgs: string | undefined,
    createInvoice: () => void,
    creatingInvoice: boolean,
    isLoadingClaimsInfo: boolean,
    isFetchingClaims: boolean,
    pageIndex: number,
    pageSize: number,
    setPagination: OnChangeFn<PaginationState>,
    onOpenInvoiceAddressModal: () => void,
    sorting: SortingState,
    setSorting: OnChangeFn<SortingState>
};

const ClaimsTable = React.memo(function ClaimsTable({
    searchResult,
    claimIdRef,
    invoiceArgs,
    claimsInfoArgs,
    createInvoice,
    creatingInvoice,
    isLoadingClaimsInfo,
    isFetchingClaims,
    pageIndex,
    pageSize,
    setPagination,
    onOpenInvoiceAddressModal,
    sorting,
    setSorting
}: ClaimsTableProps) {
    const statusBadge = (status: string) => {
        switch (status) {
            case "Open":
                return <Badge variant="orange">{status}</Badge>;
            case "Closed":
                return <Badge variant="gray">{status}</Badge>;
            case "VAT Missing":
                return <Badge variant="red">{status}</Badge>;
            default:
                return <Badge>{status}</Badge>;
        }
    };

    const { t } = useTranslation("claimsInvoicesOverview");
    const isSortListOfClaimsEnabled = useFeature("CIA-566-SortListOfClaims") as boolean;

    const copyBtn = useCallback((e: React.MouseEvent<HTMLElement>, claimId: string) => {
        e.stopPropagation();
        claimIdRef.current = claimId;
        onOpenInvoiceAddressModal();
    }, [claimIdRef, onOpenInvoiceAddressModal]);

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize
        }),
        [pageIndex, pageSize]
    );

    const tableText = (tableId: string) => ({ row }: CellContext<ClaimsItemDto, unknown>) =>
            <TruncatedText text={row.getValue(tableId)} />;

    const columns: ColumnDef<ClaimsItemDto>[] = useMemo(() => [
        {
            header: t("claimsTable.incidentDateHeader") as string | undefined,
            accessorKey: "incidentDate",
            cell: ({ row }) => {
                return (
                    <Flex gap="0.5rem">
                        {row.getIsExpanded() ? (
                            <Icon
                                id={"closeExpanderBtn" + row.id}
                                aria-label="expand"
                                as={FiChevronUp}
                            />
                        ) : (
                            <Icon
                                id={"openExpanderBtn" + row.id}
                                aria-label="close"
                                as={FiChevronDown}
                            />
                        )}
                        <Text>{row.getValue("incidentDate")}</Text>
                    </Flex>
                );
            },
            enableSorting: isSortListOfClaimsEnabled,
            sortDescFirst: true
        },
        {
            header: t("claimsTable.claimsIdHeader") as string | undefined,
            id: "id",
            accessorKey: "id",
            cell: tableText("id"),
            enableSorting: isSortListOfClaimsEnabled,
            sortDescFirst: true
        },
        {
            header: t("claimsTable.descriptionHeader") as string | undefined,
            accessorKey: "description",
            cell: tableText("description"),
            enableSorting: false
        },
        {
            header: t("claimsTable.vesselHeader") as string | undefined,
            accessorKey: "vesselName",
            cell: tableText("vesselName"),
            enableSorting: isSortListOfClaimsEnabled,
            sortDescFirst: true
        },
        {
            header: t("claimsTable.address")!,
            accessorKey: "address",
            cell: ({ row }) => (
                <Flex gap="0.5rem" alignItems="center" justifyContent="center" w="100%">
                    <Tooltip hasArrow label={t("claimsTable.copyInvoiceAddress")} placement="top">
                        <span>
                            <IconButton
                                id={"copyBtn" + row.id}
                                size="sm"
                                aria-label={t("claimsTable.copyInvoiceAddress")}
                                as={FiCopy}
                                ref={null}
                                variant="primary"
                                bg="anchor.navy.50"
                                color="anchor.navy.900"
                                _hover={{
                                    bg: "anchor.navy.100",
                                    color: "anchor.navy.900"
                                }}
                                p="0.2rem"
                                onClick={(e) => copyBtn(e, row.original.id)}
                                tabIndex={0}
                            />
                        </span>
                    </Tooltip>
                </Flex>
            ),
            enableSorting: false,
            minSize: 70,
            maxSize: 75
        },
        {
            header: t("claimsTable.clientHeader") as string | undefined,
            id: "clientName",
            accessorKey: "clientName",
            enableSorting: false
        },
        {
            header: t("claimsTable.invoicesHeader") as string | undefined,
            id: "invoiceCount",
            accessorKey: "invoiceCount",
            cell: ({ row }) => (
                <Flex gap="0.5rem" alignItems="center" justifyContent="end">
                    <Text>{row.getValue("invoiceCount")}</Text>
                    <Tooltip size="md" hasArrow label={t("claimsTable.createNewInvoice")} placement="top">
                        <span>
                            <CreateInvoiceButton
                                rowId={row.id}
                                claimId={row.getValue("id")}
                                claimIdRef={claimIdRef}
                                createInvoice={createInvoice}
                                creatingInvoice={creatingInvoice}
                                invoiceArgs={invoiceArgs}
                                isLoadingClaimsInfo={isLoadingClaimsInfo}
                                claimsInfoArgs={claimsInfoArgs}
                            />
                        </span>
                    </Tooltip>
                </Flex>
            ),
            minSize: 60,
            maxSize: 85,
            enableSorting: false
        },
        {
            header: t("claimsTable.statusHeader") as string | undefined,
            id: "status",
            accessorKey: "status",
            cell: ({ row }) => statusBadge(row.getValue("status")),
            maxSize: 65,
            enableSorting: false
        }
    ], [t, isSortListOfClaimsEnabled, copyBtn, claimIdRef,
        createInvoice, creatingInvoice, invoiceArgs, isLoadingClaimsInfo, claimsInfoArgs]);

    const handlePageChange = useCallback((page: number) => {
        setPagination({ pageSize, pageIndex: page });
    }, [pageSize, setPagination]);

    const handlePageSizeChange = useCallback((size: number) => {
        setPagination({ pageIndex: 1, pageSize: size });
    }, [setPagination]);

    const table = useReactTable<ClaimsItemDto>({
        columns,
        data: searchResult?.items ?? [],
        pageCount: searchResult?.totalResults,
        state: {
            pagination,
            sorting
        },
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        manualPagination: true,
        manualSorting: true,
        getRowCanExpand: () => true,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        enableSortingRemoval: false
    });

    const AnchorPagination = chakra(Pagination);

    return (
        <TableContainer
            data-testid="claimsTable"
            overflowY="auto"
            border="2px solid"
            borderRadius="5px"
            borderColor={"anchor.brown.200"}
            maxH="60rem"
        >
            <TableContent table={table}>
                {table.getRowModel().rows.map(row => <ClaimsTableRow key={row.original.id} row={row} />)}
            </TableContent>
            { !searchResult?.items?.length && !isFetchingClaims && (
                <Text>{t("emptyClaimsList")}</Text>
            )}
            <AnchorPagination
                page={pageIndex}
                pageSize={pageSize}
                returnedResults={searchResult?.items?.length ?? 0}
                totalResults={searchResult?.totalResults ?? 0}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                alignItems="center"
                position="sticky"
                bottom={0}
                zIndex={1}
                bg="anchor.white"
                maxW="100%"
            />
        </TableContainer>
    );
});

export default ClaimsTable;
